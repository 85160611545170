/*------------------------
	font icons
-------------------------*/

.nc-icon-mini.social_logo-500px:before {
    content: "\eb73";
}
.nc-icon-mini.social_logo-angellist:before {
    content: "\eb74";
}
.nc-icon-mini.social_logo-behance:before {
    content: "\eb75";
}
.nc-icon-mini.social_logo-blogger:before {
    content: "\eb76";
}
.nc-icon-mini.social_logo-buffer:before {
    content: "\eb77";
}
.nc-icon-mini.social_logo-buysellads:before {
    content: "\eb78";
}
.nc-icon-mini.social_logo-codepen:before {
    content: "\eb79";
}
.nc-icon-mini.social_logo-creative-market:before {
    content: "\eb7a";
}
.nc-icon-mini.social_logo-crunchbase:before {
    content: "\eb7b";
}
.nc-icon-mini.social_logo-deviantart:before {
    content: "\eb7c";
}
.nc-icon-mini.social_logo-dribbble:before {
    content: "\eb7d";
}
.nc-icon-mini.social_logo-dropbox:before {
    content: "\eb7e";
}
.nc-icon-mini.social_logo-envato:before {
    content: "\eb7f";
}
.nc-icon-mini.social_logo-evernote:before {
    content: "\eb80";
}
.nc-icon-mini.social_logo-facebook:before {
    content: "\eb81";
}
.nc-icon-mini.social_logo-fb-simple:before {
    content: "\eb82";
}
.nc-icon-mini.social_logo-feedly:before {
    content: "\eb83";
}
.nc-icon-mini.social_logo-flickr:before {
    content: "\eb84";
}
.nc-icon-mini.social_logo-github:before {
    content: "\eb85";
}
.nc-icon-mini.social_logo-google-plus:before {
    content: "\eb86";
}
.nc-icon-mini.social_logo-instagram:before {
    content: "\eb87";
}
.nc-icon-mini.social_logo-lastfm:before {
    content: "\eb88";
}
.nc-icon-mini.social_logo-linkedin:before {
    content: "\eb89";
}
.nc-icon-mini.social_logo-meetup:before {
    content: "\eb8a";
}
.nc-icon-mini.social_logo-myspace:before {
    content: "\eb8b";
}
.nc-icon-mini.social_logo-paypal:before {
    content: "\eb8c";
}
.nc-icon-mini.social_logo-pinterest:before {
    content: "\eb8d";
}
.nc-icon-mini.social_logo-product-hunt:before {
    content: "\eb8e";
}
.nc-icon-mini.social_logo-reddit:before {
    content: "\eb8f";
}
.nc-icon-mini.social_logo-rss:before {
    content: "\eb90";
}
.nc-icon-mini.social_logo-shopify:before {
    content: "\eb91";
}
.nc-icon-mini.social_logo-skype:before {
    content: "\eb92";
}
.nc-icon-mini.social_logo-slack:before {
    content: "\eb93";
}
.nc-icon-mini.social_logo-soundcloud:before {
    content: "\eb94";
}
.nc-icon-mini.social_logo-spotify:before {
    content: "\eb95";
}
.nc-icon-mini.social_logo-trello:before {
    content: "\eb96";
}
.nc-icon-mini.social_logo-tumblr:before {
    content: "\eb97";
}
.nc-icon-mini.social_logo-twitter:before {
    content: "\eb98";
}
.nc-icon-mini.social_logo-vimeo:before {
    content: "\eb99";
}
.nc-icon-mini.social_logo-vine:before {
    content: "\eb9a";
}
.nc-icon-mini.social_logo-whatsapp:before {
    content: "\eb9b";
}
.nc-icon-mini.social_logo-wordpress:before {
    content: "\eb9c";
}
.nc-icon-mini.social_logo-yelp:before {
    content: "\eb9d";
}
.nc-icon-mini.social_logo-youtube:before {
    content: "\eb9e";
}
.nc-icon-mini.design_album:before {
    content: "\ed88";
}
.nc-icon-mini.design_align-bottom:before {
    content: "\ed89";
}
.nc-icon-mini.design_align-center-horizontal:before {
    content: "\ed8a";
}
.nc-icon-mini.design_align-center-vertical:before {
    content: "\ed8b";
}
.nc-icon-mini.design_align-left:before {
    content: "\ed8c";
}
.nc-icon-mini.design_align-right:before {
    content: "\ed8d";
}
.nc-icon-mini.design_align-top:before {
    content: "\ed8e";
}
.nc-icon-mini.design_app:before {
    content: "\ed8f";
}
.nc-icon-mini.design_artboard:before {
    content: "\ed90";
}
.nc-icon-mini.design_blend:before {
    content: "\ed91";
}
.nc-icon-mini.design_book-bookmark:before {
    content: "\ed92";
}
.nc-icon-mini.design_book-open:before {
    content: "\ed93";
}
.nc-icon-mini.design_brush:before {
    content: "\ed94";
}
.nc-icon-mini.design_bug:before {
    content: "\ed95";
}
.nc-icon-mini.design_bullet-list-67:before {
    content: "\ed96";
}
.nc-icon-mini.design_bullet-list-68:before {
    content: "\ed97";
}
.nc-icon-mini.design_bullet-list-69:before {
    content: "\ed98";
}
.nc-icon-mini.design_bullet-list-70:before {
    content: "\ed99";
}
.nc-icon-mini.design_clone:before {
    content: "\ed9a";
}
.nc-icon-mini.design_code:before {
    content: "\ed9b";
}
.nc-icon-mini.design_collection:before {
    content: "\ed9c";
}
.nc-icon-mini.design_command:before {
    content: "\ed9d";
}
.nc-icon-mini.design_compass:before {
    content: "\ed9e";
}
.nc-icon-mini.design_contrast:before {
    content: "\ed9f";
}
.nc-icon-mini.design_copy:before {
    content: "\eda0";
}
.nc-icon-mini.design_crop:before {
    content: "\eda1";
}
.nc-icon-mini.design_cursor-48:before {
    content: "\eda2";
}
.nc-icon-mini.design_cursor-49:before {
    content: "\eda3";
}
.nc-icon-mini.design_design:before {
    content: "\eda4";
}
.nc-icon-mini.design_distribute-horizontal:before {
    content: "\eda5";
}
.nc-icon-mini.design_distribute-vertical:before {
    content: "\eda6";
}
.nc-icon-mini.design_eraser-32:before {
    content: "\eda7";
}
.nc-icon-mini.design_eraser-33:before {
    content: "\eda8";
}
.nc-icon-mini.design_eraser-46:before {
    content: "\eda9";
}
.nc-icon-mini.design_flip-horizontal:before {
    content: "\edaa";
}
.nc-icon-mini.design_flip-vertical:before {
    content: "\edab";
}
.nc-icon-mini.design_image:before {
    content: "\edac";
}
.nc-icon-mini.design_magnet:before {
    content: "\edad";
}
.nc-icon-mini.design_marker:before {
    content: "\edae";
}
.nc-icon-mini.design_measure-02:before {
    content: "\edaf";
}
.nc-icon-mini.design_measure-17:before {
    content: "\edb0";
}
.nc-icon-mini.design_measure-big:before {
    content: "\edb1";
}
.nc-icon-mini.design_mouse-08:before {
    content: "\edb2";
}
.nc-icon-mini.design_mouse-09:before {
    content: "\edb3";
}
.nc-icon-mini.design_mouse-10:before {
    content: "\edb4";
}
.nc-icon-mini.design_note-code:before {
    content: "\edb5";
}
.nc-icon-mini.design_paint-16:before {
    content: "\edb6";
}
.nc-icon-mini.design_paint-37:before {
    content: "\edb7";
}
.nc-icon-mini.design_paint-38:before {
    content: "\edb8";
}
.nc-icon-mini.design_paint-bucket-39:before {
    content: "\edb9";
}
.nc-icon-mini.design_paint-bucket-40:before {
    content: "\edba";
}
.nc-icon-mini.design_palette:before {
    content: "\edbb";
}
.nc-icon-mini.design_pantone:before {
    content: "\edbc";
}
.nc-icon-mini.design_patch-19:before {
    content: "\edbd";
}
.nc-icon-mini.design_patch-34:before {
    content: "\edbe";
}
.nc-icon-mini.design_path-exclude:before {
    content: "\edbf";
}
.nc-icon-mini.design_path-intersect:before {
    content: "\edc0";
}
.nc-icon-mini.design_path-minus:before {
    content: "\edc1";
}
.nc-icon-mini.design_path-unite:before {
    content: "\edc2";
}
.nc-icon-mini.design_pen-01:before {
    content: "\edc3";
}
.nc-icon-mini.design_pen-23:before {
    content: "\edc4";
}
.nc-icon-mini.design_pen-tool:before {
    content: "\edc5";
}
.nc-icon-mini.design_phone:before {
    content: "\edc6";
}
.nc-icon-mini.design_scissors:before {
    content: "\edc7";
}
.nc-icon-mini.design_shape-adjust:before {
    content: "\edc8";
}
.nc-icon-mini.design_shape-circle:before {
    content: "\edc9";
}
.nc-icon-mini.design_shape-polygon:before {
    content: "\edca";
}
.nc-icon-mini.design_shape-square:before {
    content: "\edcb";
}
.nc-icon-mini.design_shape-triangle:before {
    content: "\edcc";
}
.nc-icon-mini.design_shapes:before {
    content: "\edcd";
}
.nc-icon-mini.design_sharpener:before {
    content: "\edce";
}
.nc-icon-mini.design_slice:before {
    content: "\edcf";
}
.nc-icon-mini.design_spray:before {
    content: "\edd0";
}
.nc-icon-mini.design_stamp:before {
    content: "\edd1";
}
.nc-icon-mini.design_tablet:before {
    content: "\edd2";
}
.nc-icon-mini.design_text:before {
    content: "\edd3";
}
.nc-icon-mini.design_todo:before {
    content: "\edd4";
}
.nc-icon-mini.design_usb:before {
    content: "\edd5";
}
.nc-icon-mini.design_vector:before {
    content: "\edd6";
}
.nc-icon-mini.design_wand:before {
    content: "\edd7";
}
.nc-icon-mini.design_webpage:before {
    content: "\edd8";
}
.nc-icon-mini.design_window-code:before {
    content: "\edd9";
}
.nc-icon-mini.design_window-paragraph:before {
    content: "\edda";
}
.nc-icon-mini.design_window-responsive:before {
    content: "\eddb";
}
.nc-icon-mini.design-2_3d-28:before {
    content: "\eea7";
}
.nc-icon-mini.design-2_3d-29:before {
    content: "\eea8";
}
.nc-icon-mini.design-2_android:before {
    content: "\eea9";
}
.nc-icon-mini.design-2_angle:before {
    content: "\eeaa";
}
.nc-icon-mini.design-2_animation-14:before {
    content: "\eeab";
}
.nc-icon-mini.design-2_animation-31:before {
    content: "\eeac";
}
.nc-icon-mini.design-2_animation-32:before {
    content: "\eead";
}
.nc-icon-mini.design-2_apple:before {
    content: "\eeae";
}
.nc-icon-mini.design-2_bucket:before {
    content: "\eeaf";
}
.nc-icon-mini.design-2_button:before {
    content: "\eeb0";
}
.nc-icon-mini.design-2_canvas:before {
    content: "\eeb1";
}
.nc-icon-mini.design-2_css3:before {
    content: "\eeb2";
}
.nc-icon-mini.design-2_cursor-add:before {
    content: "\eeb3";
}
.nc-icon-mini.design-2_cursor-grab:before {
    content: "\eeb4";
}
.nc-icon-mini.design-2_cursor-load:before {
    content: "\eeb5";
}
.nc-icon-mini.design-2_cursor-menu:before {
    content: "\eeb6";
}
.nc-icon-mini.design-2_cursor-not-allowed:before {
    content: "\eeb7";
}
.nc-icon-mini.design-2_cursor-pointer:before {
    content: "\eeb8";
}
.nc-icon-mini.design-2_cursor-text:before {
    content: "\eeb9";
}
.nc-icon-mini.design-2_divider:before {
    content: "\eeba";
}
.nc-icon-mini.design-2_filter-organization:before {
    content: "\eebb";
}
.nc-icon-mini.design-2_form:before {
    content: "\eebc";
}
.nc-icon-mini.design-2_frame:before {
    content: "\eebd";
}
.nc-icon-mini.design-2_group:before {
    content: "\eebe";
}
.nc-icon-mini.design-2_html5:before {
    content: "\eebf";
}
.nc-icon-mini.design-2_image:before {
    content: "\eec0";
}
.nc-icon-mini.design-2_layers:before {
    content: "\eec1";
}
.nc-icon-mini.design-2_layout-11:before {
    content: "\eec2";
}
.nc-icon-mini.design-2_layout-25:before {
    content: "\eec3";
}
.nc-icon-mini.design-2_mirror:before {
    content: "\eec4";
}
.nc-icon-mini.design-2_move-down:before {
    content: "\eec5";
}
.nc-icon-mini.design-2_move-up:before {
    content: "\eec6";
}
.nc-icon-mini.design-2_paint-brush:before {
    content: "\eec7";
}
.nc-icon-mini.design-2_ruler-pencil:before {
    content: "\eec8";
}
.nc-icon-mini.design-2_scale-down:before {
    content: "\eec9";
}
.nc-icon-mini.design-2_scale-up:before {
    content: "\eeca";
}
.nc-icon-mini.design-2_scale:before {
    content: "\eecb";
}
.nc-icon-mini.design-2_selection:before {
    content: "\eecc";
}
.nc-icon-mini.design-2_slider:before {
    content: "\eecd";
}
.nc-icon-mini.design-2_text:before {
    content: "\eece";
}
.nc-icon-mini.design-2_transform-origin:before {
    content: "\eecf";
}
.nc-icon-mini.design-2_transform:before {
    content: "\eed0";
}
.nc-icon-mini.design-2_ungroup:before {
    content: "\eed1";
}
.nc-icon-mini.loader_circle-04:before {
    content: "\eea3";
}
.nc-icon-mini.loader_dots-06:before {
    content: "\eea4";
}
.nc-icon-mini.loader_gear:before {
    content: "\eea5";
}
.nc-icon-mini.loader_refresh:before {
    content: "\eea6";
}
.nc-icon-mini.ui-1_analytics-88:before {
    content: "\ea0f";
}
.nc-icon-mini.ui-1_analytics-89:before {
    content: "\ea10";
}
.nc-icon-mini.ui-1_attach-86:before {
    content: "\ea11";
}
.nc-icon-mini.ui-1_attach-87:before {
    content: "\ea12";
}
.nc-icon-mini.ui-1_bell-53:before {
    content: "\ea13";
}
.nc-icon-mini.ui-1_bell-54:before {
    content: "\ea14";
}
.nc-icon-mini.ui-1_bell-55:before {
    content: "\ea15";
}
.nc-icon-mini.ui-1_bold-add:before {
    content: "\ea16";
}
.nc-icon-mini.ui-1_bold-delete:before {
    content: "\ea17";
}
.nc-icon-mini.ui-1_bold-remove:before {
    content: "\ea18";
}
.nc-icon-mini.ui-1_bookmark-add:before {
    content: "\ea19";
}
.nc-icon-mini.ui-1_bookmark-remove:before {
    content: "\ea1a";
}
.nc-icon-mini.ui-1_calendar-57:before {
    content: "\ea1b";
}
.nc-icon-mini.ui-1_calendar-60:before {
    content: "\ea1c";
}
.nc-icon-mini.ui-1_check-bold:before {
    content: "\ea1d";
}
.nc-icon-mini.ui-1_check-circle-07:before {
    content: "\ea1e";
}
.nc-icon-mini.ui-1_check-circle-08:before {
    content: "\ea1f";
}
.nc-icon-mini.ui-1_check-curve:before {
    content: "\ea20";
}
.nc-icon-mini.ui-1_check-simple:before {
    content: "\ea21";
}
.nc-icon-mini.ui-1_check-small:before {
    content: "\ea22";
}
.nc-icon-mini.ui-1_check-square-09:before {
    content: "\ea23";
}
.nc-icon-mini.ui-1_check-square-11:before {
    content: "\ea24";
}
.nc-icon-mini.ui-1_check:before {
    content: "\ea25";
}
.nc-icon-mini.ui-1_circle-add:before {
    content: "\ea26";
}
.nc-icon-mini.ui-1_circle-bold-add:before {
    content: "\ea27";
}
.nc-icon-mini.ui-1_circle-bold-remove:before {
    content: "\ea28";
}
.nc-icon-mini.ui-1_circle-delete:before {
    content: "\ea29";
}
.nc-icon-mini.ui-1_circle-remove:before {
    content: "\ea2a";
}
.nc-icon-mini.ui-1_dashboard-29:before {
    content: "\ea2b";
}
.nc-icon-mini.ui-1_dashboard-30:before {
    content: "\ea2c";
}
.nc-icon-mini.ui-1_dashboard-half:before {
    content: "\ea2d";
}
.nc-icon-mini.ui-1_dashboard-level:before {
    content: "\ea2e";
}
.nc-icon-mini.ui-1_database:before {
    content: "\ea2f";
}
.nc-icon-mini.ui-1_drop:before {
    content: "\ea30";
}
.nc-icon-mini.ui-1_edit-71:before {
    content: "\ea31";
}
.nc-icon-mini.ui-1_edit-72:before {
    content: "\ea32";
}
.nc-icon-mini.ui-1_edit-73:before {
    content: "\ea33";
}
.nc-icon-mini.ui-1_edit-74:before {
    content: "\ea34";
}
.nc-icon-mini.ui-1_edit-75:before {
    content: "\ea35";
}
.nc-icon-mini.ui-1_edit-76:before {
    content: "\ea36";
}
.nc-icon-mini.ui-1_edit-77:before {
    content: "\ea37";
}
.nc-icon-mini.ui-1_edit-78:before {
    content: "\ea38";
}
.nc-icon-mini.ui-1_email-83:before {
    content: "\ea39";
}
.nc-icon-mini.ui-1_email-84:before {
    content: "\ea3a";
}
.nc-icon-mini.ui-1_eye-17:before {
    content: "\ea3b";
}
.nc-icon-mini.ui-1_eye-19:before {
    content: "\ea3c";
}
.nc-icon-mini.ui-1_eye-ban-18:before {
    content: "\ea3d";
}
.nc-icon-mini.ui-1_eye-ban-20:before {
    content: "\ea3e";
}
.nc-icon-mini.ui-1_flame:before {
    content: "\ea3f";
}
.nc-icon-mini.ui-1_home-51:before {
    content: "\ea40";
}
.nc-icon-mini.ui-1_home-52:before {
    content: "\ea41";
}
.nc-icon-mini.ui-1_home-minimal:before {
    content: "\ea42";
}
.nc-icon-mini.ui-1_home-simple:before {
    content: "\ea43";
}
.nc-icon-mini.ui-1_leaf-80:before {
    content: "\ea44";
}
.nc-icon-mini.ui-1_leaf-81:before {
    content: "\ea45";
}
.nc-icon-mini.ui-1_leaf-edit:before {
    content: "\ea46";
}
.nc-icon-mini.ui-1_lock-circle-open:before {
    content: "\ea47";
}
.nc-icon-mini.ui-1_lock-circle:before {
    content: "\ea48";
}
.nc-icon-mini.ui-1_lock-open:before {
    content: "\ea49";
}
.nc-icon-mini.ui-1_lock:before {
    content: "\ea4a";
}
.nc-icon-mini.ui-1_notification-69:before {
    content: "\ea4b";
}
.nc-icon-mini.ui-1_pencil:before {
    content: "\ea4c";
}
.nc-icon-mini.ui-1_preferences-circle-rotate:before {
    content: "\ea4d";
}
.nc-icon-mini.ui-1_preferences-circle:before {
    content: "\ea4e";
}
.nc-icon-mini.ui-1_preferences-container-circle-rotate:before {
    content: "\ea4f";
}
.nc-icon-mini.ui-1_preferences-container-circle:before {
    content: "\ea50";
}
.nc-icon-mini.ui-1_preferences-container-rotate:before {
    content: "\ea51";
}
.nc-icon-mini.ui-1_preferences-container:before {
    content: "\ea52";
}
.nc-icon-mini.ui-1_preferences-rotate:before {
    content: "\ea53";
}
.nc-icon-mini.ui-1_preferences:before {
    content: "\ea54";
}
.nc-icon-mini.ui-1_send:before {
    content: "\ea55";
}
.nc-icon-mini.ui-1_settings-gear-63:before {
    content: "\ea56";
}
.nc-icon-mini.ui-1_settings-gear-64:before {
    content: "\ea57";
}
.nc-icon-mini.ui-1_settings-gear-65:before {
    content: "\ea58";
}
.nc-icon-mini.ui-1_settings-tool-66:before {
    content: "\ea59";
}
.nc-icon-mini.ui-1_simple-add:before {
    content: "\ea5a";
}
.nc-icon-mini.ui-1_simple-delete:before {
    content: "\ea5b";
}
.nc-icon-mini.ui-1_simple-remove:before {
    content: "\ea5c";
}
.nc-icon-mini.ui-1_trash-simple:before {
    content: "\ea5d";
}
.nc-icon-mini.ui-1_trash:before {
    content: "\ea5e";
}
.nc-icon-mini.ui-1_ui-03:before {
    content: "\ea5f";
}
.nc-icon-mini.ui-1_ui-04:before {
    content: "\ea60";
}
.nc-icon-mini.ui-1_zoom-bold-in:before {
    content: "\ea61";
}
.nc-icon-mini.ui-1_zoom-bold-out:before {
    content: "\ea62";
}
.nc-icon-mini.ui-1_zoom-bold:before {
    content: "\ea63";
}
.nc-icon-mini.ui-1_zoom-in:before {
    content: "\ea64";
}
.nc-icon-mini.ui-1_zoom-out:before {
    content: "\ea65";
}
.nc-icon-mini.ui-1_zoom:before {
    content: "\ea66";
}
.nc-icon-mini.ui-2_alert:before {
    content: "\ea67";
}
.nc-icon-mini.ui-2_alert-:before {
    content: "\ea68";
}
.nc-icon-mini.ui-2_alert-circle:before {
    content: "\ea69";
}
.nc-icon-mini.ui-2_alert-circle-:before {
    content: "\ea6a";
}
.nc-icon-mini.ui-2_alert-circle-i:before {
    content: "\ea6b";
}
.nc-icon-mini.ui-2_alert-i:before {
    content: "\ea6c";
}
.nc-icon-mini.ui-2_alert-square:before {
    content: "\ea6d";
}
.nc-icon-mini.ui-2_alert-square-:before {
    content: "\ea6e";
}
.nc-icon-mini.ui-2_alert-square-i:before {
    content: "\ea6f";
}
.nc-icon-mini.ui-2_archive:before {
    content: "\ea70";
}
.nc-icon-mini.ui-2_ban:before {
    content: "\ea71";
}
.nc-icon-mini.ui-2_battery-81:before {
    content: "\ea72";
}
.nc-icon-mini.ui-2_battery-83:before {
    content: "\ea73";
}
.nc-icon-mini.ui-2_battery-half:before {
    content: "\ea74";
}
.nc-icon-mini.ui-2_battery-low:before {
    content: "\ea75";
}
.nc-icon-mini.ui-2_bluetooth:before {
    content: "\ea76";
}
.nc-icon-mini.ui-2_book:before {
    content: "\ea77";
}
.nc-icon-mini.ui-2_chart-bar-52:before {
    content: "\ea78";
}
.nc-icon-mini.ui-2_chart-bar-53:before {
    content: "\ea79";
}
.nc-icon-mini.ui-2_chat-content:before {
    content: "\ea7a";
}
.nc-icon-mini.ui-2_chat-round-content:before {
    content: "\ea7b";
}
.nc-icon-mini.ui-2_chat-round:before {
    content: "\ea7c";
}
.nc-icon-mini.ui-2_chat:before {
    content: "\ea7d";
}
.nc-icon-mini.ui-2_circle-bold-delete:before {
    content: "\ea7e";
}
.nc-icon-mini.ui-2_cloud-25:before {
    content: "\ea7f";
}
.nc-icon-mini.ui-2_cloud-26:before {
    content: "\ea80";
}
.nc-icon-mini.ui-2_disk:before {
    content: "\ea81";
}
.nc-icon-mini.ui-2_enlarge-57:before {
    content: "\ea82";
}
.nc-icon-mini.ui-2_enlarge-58:before {
    content: "\ea83";
}
.nc-icon-mini.ui-2_favourite-28:before {
    content: "\ea84";
}
.nc-icon-mini.ui-2_favourite-31:before {
    content: "\ea85";
}
.nc-icon-mini.ui-2_filter:before {
    content: "\ea86";
}
.nc-icon-mini.ui-2_fullsize:before {
    content: "\ea87";
}
.nc-icon-mini.ui-2_grid-45:before {
    content: "\ea88";
}
.nc-icon-mini.ui-2_grid-46:before {
    content: "\ea89";
}
.nc-icon-mini.ui-2_grid-48:before {
    content: "\ea8a";
}
.nc-icon-mini.ui-2_grid-50:before {
    content: "\ea8b";
}
.nc-icon-mini.ui-2_grid-square:before {
    content: "\ea8c";
}
.nc-icon-mini.ui-2_hourglass:before {
    content: "\ea8d";
}
.nc-icon-mini.ui-2_lab:before {
    content: "\ea8e";
}
.nc-icon-mini.ui-2_layers:before {
    content: "\ea8f";
}
.nc-icon-mini.ui-2_like:before {
    content: "\ea90";
}
.nc-icon-mini.ui-2_link-66:before {
    content: "\ea91";
}
.nc-icon-mini.ui-2_link-68:before {
    content: "\ea92";
}
.nc-icon-mini.ui-2_link-69:before {
    content: "\ea93";
}
.nc-icon-mini.ui-2_link-72:before {
    content: "\ea94";
}
.nc-icon-mini.ui-2_link-broken-70:before {
    content: "\ea95";
}
.nc-icon-mini.ui-2_link-broken-73:before {
    content: "\ea96";
}
.nc-icon-mini.ui-2_menu-34:before {
    content: "\ea97";
}
.nc-icon-mini.ui-2_menu-35:before {
    content: "\ea98";
}
.nc-icon-mini.ui-2_menu-bold:before {
    content: "\ea99";
}
.nc-icon-mini.ui-2_menu-dots:before {
    content: "\ea9a";
}
.nc-icon-mini.ui-2_menu-square:before {
    content: "\ea9b";
}
.nc-icon-mini.ui-2_node:before {
    content: "\ea9c";
}
.nc-icon-mini.ui-2_paragraph:before {
    content: "\ea9d";
}
.nc-icon-mini.ui-2_phone:before {
    content: "\ea9e";
}
.nc-icon-mini.ui-2_share-bold:before {
    content: "\ea9f";
}
.nc-icon-mini.ui-2_share:before {
    content: "\eaa0";
}
.nc-icon-mini.ui-2_small-add:before {
    content: "\eaa1";
}
.nc-icon-mini.ui-2_small-delete:before {
    content: "\eaa2";
}
.nc-icon-mini.ui-2_small-remove:before {
    content: "\eaa3";
}
.nc-icon-mini.ui-2_square-add-08:before {
    content: "\eaa4";
}
.nc-icon-mini.ui-2_square-add-11:before {
    content: "\eaa5";
}
.nc-icon-mini.ui-2_square-delete-10:before {
    content: "\eaa6";
}
.nc-icon-mini.ui-2_square-delete-13:before {
    content: "\eaa7";
}
.nc-icon-mini.ui-2_square-remove-09:before {
    content: "\eaa8";
}
.nc-icon-mini.ui-2_square-remove-12:before {
    content: "\eaa9";
}
.nc-icon-mini.ui-2_target:before {
    content: "\eaaa";
}
.nc-icon-mini.ui-2_tile-55:before {
    content: "\eaab";
}
.nc-icon-mini.ui-2_tile-56:before {
    content: "\eaac";
}
.nc-icon-mini.ui-2_time-alarm:before {
    content: "\eaad";
}
.nc-icon-mini.ui-2_time-clock:before {
    content: "\eaae";
}
.nc-icon-mini.ui-2_time-countdown:before {
    content: "\eaaf";
}
.nc-icon-mini.ui-2_time:before {
    content: "\eab0";
}
.nc-icon-mini.ui-2_webpage:before {
    content: "\eab1";
}
.nc-icon-mini.ui-3_alert:before {
    content: "\eab2";
}
.nc-icon-mini.ui-3_backward:before {
    content: "\eab3";
}
.nc-icon-mini.ui-3_bolt:before {
    content: "\eab4";
}
.nc-icon-mini.ui-3_bullet-list:before {
    content: "\eab5";
}
.nc-icon-mini.ui-3_calendar-add:before {
    content: "\eab6";
}
.nc-icon-mini.ui-3_card-add:before {
    content: "\eab7";
}
.nc-icon-mini.ui-3_card-alert:before {
    content: "\eab8";
}
.nc-icon-mini.ui-3_chart-bars:before {
    content: "\ee6e";
}
.nc-icon-mini.ui-3_chart:before {
    content: "\ee6f";
}
.nc-icon-mini.ui-3_chat-33:before {
    content: "\ee70";
}
.nc-icon-mini.ui-3_chat-45:before {
    content: "\ee71";
}
.nc-icon-mini.ui-3_chat-46:before {
    content: "\ee72";
}
.nc-icon-mini.ui-3_chat-reply:before {
    content: "\ee73";
}
.nc-icon-mini.ui-3_check-in:before {
    content: "\ee74";
}
.nc-icon-mini.ui-3_check-out:before {
    content: "\ee75";
}
.nc-icon-mini.ui-3_dock-bottom:before {
    content: "\ee76";
}
.nc-icon-mini.ui-3_dock-left:before {
    content: "\ee77";
}
.nc-icon-mini.ui-3_dock-right:before {
    content: "\ee78";
}
.nc-icon-mini.ui-3_dock-top:before {
    content: "\ee79";
}
.nc-icon-mini.ui-3_filter-check:before {
    content: "\ee7a";
}
.nc-icon-mini.ui-3_filter-remove:before {
    content: "\ee7b";
}
.nc-icon-mini.ui-3_forward:before {
    content: "\ee7c";
}
.nc-icon-mini.ui-3_funnel-39:before {
    content: "\ee7d";
}
.nc-icon-mini.ui-3_funnel-40:before {
    content: "\ee7e";
}
.nc-icon-mini.ui-3_funnel-41:before {
    content: "\ee7f";
}
.nc-icon-mini.ui-3_heart:before {
    content: "\ee80";
}
.nc-icon-mini.ui-3_infinite:before {
    content: "\ee81";
}
.nc-icon-mini.ui-3_link:before {
    content: "\ee82";
}
.nc-icon-mini.ui-3_menu-left:before {
    content: "\ee83";
}
.nc-icon-mini.ui-3_menu-right:before {
    content: "\ee84";
}
.nc-icon-mini.ui-3_menu:before {
    content: "\ee85";
}
.nc-icon-mini.ui-3_metrics:before {
    content: "\ee86";
}
.nc-icon-mini.ui-3_phone-call-end:before {
    content: "\ee87";
}
.nc-icon-mini.ui-3_phone-call:before {
    content: "\ee88";
}
.nc-icon-mini.ui-3_phone:before {
    content: "\ee89";
}
.nc-icon-mini.ui-3_playlist:before {
    content: "\ee8a";
}
.nc-icon-mini.ui-3_security:before {
    content: "\ee8b";
}
.nc-icon-mini.ui-3_segmentation:before {
    content: "\ee8c";
}
.nc-icon-mini.ui-3_select:before {
    content: "\ee8d";
}
.nc-icon-mini.ui-3_send:before {
    content: "\ee8e";
}
.nc-icon-mini.ui-3_signal:before {
    content: "\ee8f";
}
.nc-icon-mini.ui-3_slide-left:before {
    content: "\ee90";
}
.nc-icon-mini.ui-3_slide-right:before {
    content: "\ee91";
}
.nc-icon-mini.ui-3_table-left:before {
    content: "\ee92";
}
.nc-icon-mini.ui-3_table-right:before {
    content: "\ee93";
}
.nc-icon-mini.ui-3_tag:before {
    content: "\ee94";
}
.nc-icon-mini.ui-3_widget:before {
    content: "\ee95";
}
.nc-icon-mini.ui-e_round-e-alert:before {
    content: "\ee96";
}
.nc-icon-mini.ui-e_round-e-help:before {
    content: "\ee97";
}
.nc-icon-mini.ui-e_round-e-info:before {
    content: "\ee98";
}
.nc-icon-mini.ui-e_square-e-alert:before {
    content: "\ee99";
}
.nc-icon-mini.ui-e_square-e-help:before {
    content: "\ee9a";
}
.nc-icon-mini.ui-e_square-e-info:before {
    content: "\ee9b";
}
.nc-icon-mini.ui-e_star-half:before {
    content: "\ee9c";
}
.nc-icon-mini.ui-e-2_calendar-add:before {
    content: "\ee9d";
}
.nc-icon-mini.ui-e-2_dock-bottom:before {
    content: "\ee9e";
}
.nc-icon-mini.ui-e-2_dock-left:before {
    content: "\ee9f";
}
.nc-icon-mini.ui-e-2_dock-right:before {
    content: "\eea0";
}
.nc-icon-mini.ui-e-2_dock-top:before {
    content: "\eea1";
}
.nc-icon-mini.ui-e-2_heart:before {
    content: "\eea2";
}
.nc-icon-mini.envir_bulb-saver:before {
    content: "\ee5c";
}
.nc-icon-mini.envir_bulb:before {
    content: "\ee5d";
}
.nc-icon-mini.envir_car:before {
    content: "\ee5e";
}
.nc-icon-mini.envir_fuel-electric:before {
    content: "\ee5f";
}
.nc-icon-mini.envir_fuel:before {
    content: "\ee60";
}
.nc-icon-mini.envir_home:before {
    content: "\ee61";
}
.nc-icon-mini.envir_level:before {
    content: "\ee62";
}
.nc-icon-mini.envir_panel:before {
    content: "\ee63";
}
.nc-icon-mini.envir_radiation:before {
    content: "\ee64";
}
.nc-icon-mini.envir_recycling:before {
    content: "\ee65";
}
.nc-icon-mini.envir_save-planet:before {
    content: "\ee66";
}
.nc-icon-mini.envir_waste-danger:before {
    content: "\ee67";
}
.nc-icon-mini.envir_waste-recycling:before {
    content: "\ee68";
}
.nc-icon-mini.envir_waste:before {
    content: "\ee69";
}
.nc-icon-mini.envir_water-hand:before {
    content: "\ee6a";
}
.nc-icon-mini.envir_water-sink:before {
    content: "\ee6b";
}
.nc-icon-mini.envir_water:before {
    content: "\ee6c";
}
.nc-icon-mini.envir_wind:before {
    content: "\ee6d";
}
.nc-icon-mini.arrows-1_back-78:before {
    content: "\e900";
}
.nc-icon-mini.arrows-1_back-80:before {
    content: "\e901";
}
.nc-icon-mini.arrows-1_bold-down:before {
    content: "\e902";
}
.nc-icon-mini.arrows-1_bold-left:before {
    content: "\e903";
}
.nc-icon-mini.arrows-1_bold-right:before {
    content: "\e904";
}
.nc-icon-mini.arrows-1_bold-up:before {
    content: "\e905";
}
.nc-icon-mini.arrows-1_circle-down-12:before {
    content: "\e906";
}
.nc-icon-mini.arrows-1_circle-down-40:before {
    content: "\e907";
}
.nc-icon-mini.arrows-1_circle-left-10:before {
    content: "\e908";
}
.nc-icon-mini.arrows-1_circle-left-38:before {
    content: "\e909";
}
.nc-icon-mini.arrows-1_circle-right-09:before {
    content: "\e90a";
}
.nc-icon-mini.arrows-1_circle-right-37:before {
    content: "\e90b";
}
.nc-icon-mini.arrows-1_circle-up-11:before {
    content: "\e90c";
}
.nc-icon-mini.arrows-1_circle-up-39:before {
    content: "\e90d";
}
.nc-icon-mini.arrows-1_cloud-download-95:before {
    content: "\e90e";
}
.nc-icon-mini.arrows-1_cloud-upload-96:before {
    content: "\e90f";
}
.nc-icon-mini.arrows-1_curved-next:before {
    content: "\e910";
}
.nc-icon-mini.arrows-1_curved-previous:before {
    content: "\e911";
}
.nc-icon-mini.arrows-1_direction-53:before {
    content: "\e912";
}
.nc-icon-mini.arrows-1_direction-56:before {
    content: "\e913";
}
.nc-icon-mini.arrows-1_double-left:before {
    content: "\e914";
}
.nc-icon-mini.arrows-1_double-right:before {
    content: "\e915";
}
.nc-icon-mini.arrows-1_download:before {
    content: "\e916";
}
.nc-icon-mini.arrows-1_enlarge-diagonal-43:before {
    content: "\e917";
}
.nc-icon-mini.arrows-1_enlarge-diagonal-44:before {
    content: "\e918";
}
.nc-icon-mini.arrows-1_enlarge-horizontal:before {
    content: "\e919";
}
.nc-icon-mini.arrows-1_enlarge-vertical:before {
    content: "\e91a";
}
.nc-icon-mini.arrows-1_fullscreen-70:before {
    content: "\e91b";
}
.nc-icon-mini.arrows-1_fullscreen-71:before {
    content: "\e91c";
}
.nc-icon-mini.arrows-1_fullscreen-76:before {
    content: "\e91d";
}
.nc-icon-mini.arrows-1_fullscreen-77:before {
    content: "\e91e";
}
.nc-icon-mini.arrows-1_fullscreen-double-74:before {
    content: "\e91f";
}
.nc-icon-mini.arrows-1_fullscreen-double-75:before {
    content: "\e920";
}
.nc-icon-mini.arrows-1_fullscreen-split-72:before {
    content: "\e921";
}
.nc-icon-mini.arrows-1_fullscreen-split-73:before {
    content: "\e922";
}
.nc-icon-mini.arrows-1_log-in:before {
    content: "\e923";
}
.nc-icon-mini.arrows-1_log-out:before {
    content: "\e924";
}
.nc-icon-mini.arrows-1_loop-82:before {
    content: "\e925";
}
.nc-icon-mini.arrows-1_minimal-down:before {
    content: "\e926";
}
.nc-icon-mini.arrows-1_minimal-left:before {
    content: "\e927";
}
.nc-icon-mini.arrows-1_minimal-right:before {
    content: "\e928";
}
.nc-icon-mini.arrows-1_minimal-up:before {
    content: "\e929";
}
.nc-icon-mini.arrows-1_redo-79:before {
    content: "\e92a";
}
.nc-icon-mini.arrows-1_redo-81:before {
    content: "\e92b";
}
.nc-icon-mini.arrows-1_refresh-68:before {
    content: "\e92c";
}
.nc-icon-mini.arrows-1_refresh-69:before {
    content: "\e92d";
}
.nc-icon-mini.arrows-1_round-down:before {
    content: "\e92e";
}
.nc-icon-mini.arrows-1_round-left:before {
    content: "\e92f";
}
.nc-icon-mini.arrows-1_round-right:before {
    content: "\e930";
}
.nc-icon-mini.arrows-1_round-up:before {
    content: "\e931";
}
.nc-icon-mini.arrows-1_share-66:before {
    content: "\e932";
}
.nc-icon-mini.arrows-1_share-91:before {
    content: "\e933";
}
.nc-icon-mini.arrows-1_share-92:before {
    content: "\e934";
}
.nc-icon-mini.arrows-1_shuffle-97:before {
    content: "\e935";
}
.nc-icon-mini.arrows-1_shuffle-98:before {
    content: "\e936";
}
.nc-icon-mini.arrows-1_simple-down:before {
    content: "\e937";
}
.nc-icon-mini.arrows-1_simple-left:before {
    content: "\e938";
}
.nc-icon-mini.arrows-1_simple-right:before {
    content: "\e939";
}
.nc-icon-mini.arrows-1_simple-up:before {
    content: "\e93a";
}
.nc-icon-mini.arrows-1_small-triangle-down:before {
    content: "\e93b";
}
.nc-icon-mini.arrows-1_small-triangle-left:before {
    content: "\e93c";
}
.nc-icon-mini.arrows-1_small-triangle-right:before {
    content: "\e93d";
}
.nc-icon-mini.arrows-1_small-triangle-up:before {
    content: "\e93e";
}
.nc-icon-mini.arrows-1_square-down:before {
    content: "\e93f";
}
.nc-icon-mini.arrows-1_square-left:before {
    content: "\e940";
}
.nc-icon-mini.arrows-1_square-right:before {
    content: "\e941";
}
.nc-icon-mini.arrows-1_square-up:before {
    content: "\e942";
}
.nc-icon-mini.arrows-1_strong-down:before {
    content: "\e943";
}
.nc-icon-mini.arrows-1_strong-left:before {
    content: "\e944";
}
.nc-icon-mini.arrows-1_strong-right:before {
    content: "\e945";
}
.nc-icon-mini.arrows-1_strong-up:before {
    content: "\e946";
}
.nc-icon-mini.arrows-1_tail-down:before {
    content: "\e947";
}
.nc-icon-mini.arrows-1_tail-left:before {
    content: "\e948";
}
.nc-icon-mini.arrows-1_tail-right:before {
    content: "\e949";
}
.nc-icon-mini.arrows-1_tail-triangle-down:before {
    content: "\e94a";
}
.nc-icon-mini.arrows-1_tail-triangle-left:before {
    content: "\e94b";
}
.nc-icon-mini.arrows-1_tail-triangle-right:before {
    content: "\e94c";
}
.nc-icon-mini.arrows-1_tail-triangle-up:before {
    content: "\e94d";
}
.nc-icon-mini.arrows-1_tail-up:before {
    content: "\e94e";
}
.nc-icon-mini.arrows-1_trend-down:before {
    content: "\e94f";
}
.nc-icon-mini.arrows-1_trend-up:before {
    content: "\e950";
}
.nc-icon-mini.arrows-1_triangle-down-20:before {
    content: "\e951";
}
.nc-icon-mini.arrows-1_triangle-down-65:before {
    content: "\e952";
}
.nc-icon-mini.arrows-1_triangle-left-18:before {
    content: "\e953";
}
.nc-icon-mini.arrows-1_triangle-left-63:before {
    content: "\e954";
}
.nc-icon-mini.arrows-1_triangle-right-17:before {
    content: "\e955";
}
.nc-icon-mini.arrows-1_triangle-right-62:before {
    content: "\e956";
}
.nc-icon-mini.arrows-1_triangle-up-19:before {
    content: "\e957";
}
.nc-icon-mini.arrows-1_triangle-up-64:before {
    content: "\e958";
}
.nc-icon-mini.arrows-1_zoom-88:before {
    content: "\e959";
}
.nc-icon-mini.arrows-2_block-down:before {
    content: "\e95a";
}
.nc-icon-mini.arrows-2_block-left:before {
    content: "\e95b";
}
.nc-icon-mini.arrows-2_block-right:before {
    content: "\e95c";
}
.nc-icon-mini.arrows-2_block-up:before {
    content: "\e95d";
}
.nc-icon-mini.arrows-2_conversion:before {
    content: "\e95e";
}
.nc-icon-mini.arrows-2_corner-down-round:before {
    content: "\e95f";
}
.nc-icon-mini.arrows-2_corner-down:before {
    content: "\e960";
}
.nc-icon-mini.arrows-2_corner-left-down:before {
    content: "\e961";
}
.nc-icon-mini.arrows-2_corner-left-round:before {
    content: "\e962";
}
.nc-icon-mini.arrows-2_corner-left:before {
    content: "\e963";
}
.nc-icon-mini.arrows-2_corner-right-down:before {
    content: "\e964";
}
.nc-icon-mini.arrows-2_corner-right-round:before {
    content: "\e965";
}
.nc-icon-mini.arrows-2_corner-right:before {
    content: "\e966";
}
.nc-icon-mini.arrows-2_corner-up-left:before {
    content: "\e967";
}
.nc-icon-mini.arrows-2_corner-up-right:before {
    content: "\e968";
}
.nc-icon-mini.arrows-2_corner-up-round:before {
    content: "\e969";
}
.nc-icon-mini.arrows-2_corner-up:before {
    content: "\e96a";
}
.nc-icon-mini.arrows-2_cross-down:before {
    content: "\e96b";
}
.nc-icon-mini.arrows-2_cross-left:before {
    content: "\e96c";
}
.nc-icon-mini.arrows-2_cross-right:before {
    content: "\e96d";
}
.nc-icon-mini.arrows-2_cross-up:before {
    content: "\e96e";
}
.nc-icon-mini.arrows-2_delete-49:before {
    content: "\e96f";
}
.nc-icon-mini.arrows-2_delete-50:before {
    content: "\e970";
}
.nc-icon-mini.arrows-2_direction:before {
    content: "\e971";
}
.nc-icon-mini.arrows-2_dots-download:before {
    content: "\e972";
}
.nc-icon-mini.arrows-2_dots-upload:before {
    content: "\e973";
}
.nc-icon-mini.arrows-2_eject:before {
    content: "\e974";
}
.nc-icon-mini.arrows-2_enlarge-circle:before {
    content: "\e975";
}
.nc-icon-mini.arrows-2_file-download-87:before {
    content: "\e976";
}
.nc-icon-mini.arrows-2_file-upload-86:before {
    content: "\e977";
}
.nc-icon-mini.arrows-2_hit-down:before {
    content: "\e978";
}
.nc-icon-mini.arrows-2_hit-left:before {
    content: "\e979";
}
.nc-icon-mini.arrows-2_hit-right:before {
    content: "\e97a";
}
.nc-icon-mini.arrows-2_hit-up:before {
    content: "\e97b";
}
.nc-icon-mini.arrows-2_log-out:before {
    content: "\e97c";
}
.nc-icon-mini.arrows-2_move-down-right:before {
    content: "\e97d";
}
.nc-icon-mini.arrows-2_move-down:before {
    content: "\e97e";
}
.nc-icon-mini.arrows-2_move-left:before {
    content: "\e97f";
}
.nc-icon-mini.arrows-2_move-right:before {
    content: "\e980";
}
.nc-icon-mini.arrows-2_move-up-left:before {
    content: "\e981";
}
.nc-icon-mini.arrows-2_move-up:before {
    content: "\e982";
}
.nc-icon-mini.arrows-2_push-next:before {
    content: "\e983";
}
.nc-icon-mini.arrows-2_push-previous:before {
    content: "\e984";
}
.nc-icon-mini.arrows-2_replay:before {
    content: "\e985";
}
.nc-icon-mini.arrows-2_round-left-down:before {
    content: "\e986";
}
.nc-icon-mini.arrows-2_round-right-down:before {
    content: "\e987";
}
.nc-icon-mini.arrows-2_round-up-left:before {
    content: "\e988";
}
.nc-icon-mini.arrows-2_round-up-right:before {
    content: "\e989";
}
.nc-icon-mini.arrows-2_select-83:before {
    content: "\e98a";
}
.nc-icon-mini.arrows-2_select-84:before {
    content: "\e98b";
}
.nc-icon-mini.arrows-2_skew-down:before {
    content: "\e98c";
}
.nc-icon-mini.arrows-2_skew-left:before {
    content: "\e98d";
}
.nc-icon-mini.arrows-2_skew-right:before {
    content: "\e98e";
}
.nc-icon-mini.arrows-2_skew-up:before {
    content: "\e98f";
}
.nc-icon-mini.arrows-2_small-left:before {
    content: "\e990";
}
.nc-icon-mini.arrows-2_small-right:before {
    content: "\e991";
}
.nc-icon-mini.arrows-2_square-download:before {
    content: "\e992";
}
.nc-icon-mini.arrows-2_square-upload:before {
    content: "\e993";
}
.nc-icon-mini.arrows-2_triangle-down:before {
    content: "\e994";
}
.nc-icon-mini.arrows-2_triangle-left:before {
    content: "\e995";
}
.nc-icon-mini.arrows-2_triangle-right:before {
    content: "\e996";
}
.nc-icon-mini.arrows-2_triangle-up:before {
    content: "\e997";
}
.nc-icon-mini.arrows-2_zoom:before {
    content: "\e998";
}
.nc-icon-mini.arrows-3_circle-simple-down:before {
    content: "\e999";
}
.nc-icon-mini.arrows-3_circle-simple-left:before {
    content: "\e99a";
}
.nc-icon-mini.arrows-3_circle-simple-right:before {
    content: "\e99b";
}
.nc-icon-mini.arrows-3_circle-simple-up:before {
    content: "\e99c";
}
.nc-icon-mini.arrows-3_small-down:before {
    content: "\e99d";
}
.nc-icon-mini.arrows-3_small-up:before {
    content: "\e99e";
}
.nc-icon-mini.arrows-3_square-corner-down-left:before {
    content: "\e99f";
}
.nc-icon-mini.arrows-3_square-corner-down-right:before {
    content: "\e9a0";
}
.nc-icon-mini.arrows-3_square-corner-up-left:before {
    content: "\e9a1";
}
.nc-icon-mini.arrows-3_square-corner-up-right:before {
    content: "\e9a2";
}
.nc-icon-mini.arrows-3_square-down-06:before {
    content: "\e9a3";
}
.nc-icon-mini.arrows-3_square-left-04:before {
    content: "\e9a4";
}
.nc-icon-mini.arrows-3_square-right-03:before {
    content: "\e9a5";
}
.nc-icon-mini.arrows-3_square-simple-down:before {
    content: "\e9a6";
}
.nc-icon-mini.arrows-3_square-simple-left:before {
    content: "\e9a7";
}
.nc-icon-mini.arrows-3_square-simple-right:before {
    content: "\e9a8";
}
.nc-icon-mini.arrows-3_square-simple-up:before {
    content: "\e9a9";
}
.nc-icon-mini.arrows-3_square-up-05:before {
    content: "\e9aa";
}
.nc-icon-mini.arrows-e_archive-e-download:before {
    content: "\e9ab";
}
.nc-icon-mini.arrows-e_archive-e-upload:before {
    content: "\e9ac";
}
.nc-icon-mini.arrows-e_circle-e-down-04:before {
    content: "\e9ad";
}
.nc-icon-mini.arrows-e_circle-e-down-12:before {
    content: "\e9ae";
}
.nc-icon-mini.arrows-e_circle-e-left-02:before {
    content: "\e9af";
}
.nc-icon-mini.arrows-e_circle-e-left-10:before {
    content: "\e9b0";
}
.nc-icon-mini.arrows-e_circle-e-right-01:before {
    content: "\e9b1";
}
.nc-icon-mini.arrows-e_circle-e-right-09:before {
    content: "\e9b2";
}
.nc-icon-mini.arrows-e_circle-e-up-03:before {
    content: "\e9b3";
}
.nc-icon-mini.arrows-e_circle-e-up-11:before {
    content: "\e9b4";
}
.nc-icon-mini.arrows-e_enlarge-21:before {
    content: "\e9b5";
}
.nc-icon-mini.arrows-e_enlarge-22:before {
    content: "\e9b6";
}
.nc-icon-mini.arrows-e_expand:before {
    content: "\e9b7";
}
.nc-icon-mini.arrows-e_move-bottom-right:before {
    content: "\e9b8";
}
.nc-icon-mini.arrows-e_move-top-left:before {
    content: "\e9b9";
}
.nc-icon-mini.arrows-e_refresh-19:before {
    content: "\e9ba";
}
.nc-icon-mini.arrows-e_refresh-20:before {
    content: "\e9bb";
}
.nc-icon-mini.arrows-e_resize-h:before {
    content: "\e9bc";
}
.nc-icon-mini.arrows-e_resize-v:before {
    content: "\e9bd";
}
.nc-icon-mini.arrows-e_share-26:before {
    content: "\e9be";
}
.nc-icon-mini.arrows-e_share-27:before {
    content: "\e9bf";
}
.nc-icon-mini.arrows-e_square-e-down:before {
    content: "\e9c0";
}
.nc-icon-mini.arrows-e_square-e-left:before {
    content: "\e9c1";
}
.nc-icon-mini.arrows-e_square-e-right:before {
    content: "\e9c2";
}
.nc-icon-mini.arrows-e_square-e-up:before {
    content: "\e9c3";
}
.nc-icon-mini.arrows-e_zoom-circle:before {
    content: "\e9c4";
}
.nc-icon-mini.arrows-e_zoom-e:before {
    content: "\e9c5";
}
.nc-icon-mini.arrows-e_zoom-square:before {
    content: "\e9c6";
}
.nc-icon-mini.arrows-e_zoom-triangles:before {
    content: "\e9c7";
}
.nc-icon-mini.weather_celsius:before {
    content: "\e9c8";
}
.nc-icon-mini.weather_cloud-drop:before {
    content: "\e9c9";
}
.nc-icon-mini.weather_cloud-fog-31:before {
    content: "\e9ca";
}
.nc-icon-mini.weather_cloud-fog-32:before {
    content: "\e9cb";
}
.nc-icon-mini.weather_cloud-hail:before {
    content: "\e9cc";
}
.nc-icon-mini.weather_cloud-light:before {
    content: "\e9cd";
}
.nc-icon-mini.weather_cloud-moon:before {
    content: "\e9ce";
}
.nc-icon-mini.weather_cloud-rain:before {
    content: "\e9cf";
}
.nc-icon-mini.weather_cloud-snow-34:before {
    content: "\e9d0";
}
.nc-icon-mini.weather_cloud-snow-42:before {
    content: "\e9d1";
}
.nc-icon-mini.weather_cloud-sun-17:before {
    content: "\e9d2";
}
.nc-icon-mini.weather_compass:before {
    content: "\e9d3";
}
.nc-icon-mini.weather_drop-15:before {
    content: "\e9d4";
}
.nc-icon-mini.weather_drops:before {
    content: "\e9d5";
}
.nc-icon-mini.weather_eclipse:before {
    content: "\e9d6";
}
.nc-icon-mini.weather_fahrenheit:before {
    content: "\e9d7";
}
.nc-icon-mini.weather_fog:before {
    content: "\e9d8";
}
.nc-icon-mini.weather_forecast:before {
    content: "\e9d9";
}
.nc-icon-mini.weather_hurricane-44:before {
    content: "\e9da";
}
.nc-icon-mini.weather_hurricane-45:before {
    content: "\e9db";
}
.nc-icon-mini.weather_moon-cloud-drop:before {
    content: "\e9dc";
}
.nc-icon-mini.weather_moon-cloud-fog:before {
    content: "\e9dd";
}
.nc-icon-mini.weather_moon-cloud-hail:before {
    content: "\e9de";
}
.nc-icon-mini.weather_moon-cloud-light:before {
    content: "\e9df";
}
.nc-icon-mini.weather_moon-cloud-rain:before {
    content: "\e9e0";
}
.nc-icon-mini.weather_moon-cloud-snow-61:before {
    content: "\e9e1";
}
.nc-icon-mini.weather_moon-cloud-snow-62:before {
    content: "\e9e2";
}
.nc-icon-mini.weather_moon-fog:before {
    content: "\e9e3";
}
.nc-icon-mini.weather_moon-full:before {
    content: "\e9e4";
}
.nc-icon-mini.weather_moon-stars:before {
    content: "\e9e5";
}
.nc-icon-mini.weather_moon:before {
    content: "\e9e6";
}
.nc-icon-mini.weather_rain-hail:before {
    content: "\e9e7";
}
.nc-icon-mini.weather_rain:before {
    content: "\e9e8";
}
.nc-icon-mini.weather_rainbow:before {
    content: "\e9e9";
}
.nc-icon-mini.weather_snow:before {
    content: "\e9ea";
}
.nc-icon-mini.weather_sun-cloud-drop:before {
    content: "\e9eb";
}
.nc-icon-mini.weather_sun-cloud-fog:before {
    content: "\e9ec";
}
.nc-icon-mini.weather_sun-cloud-hail:before {
    content: "\e9ed";
}
.nc-icon-mini.weather_sun-cloud-light:before {
    content: "\e9ee";
}
.nc-icon-mini.weather_sun-cloud-rain:before {
    content: "\e9ef";
}
.nc-icon-mini.weather_sun-cloud-snow-54:before {
    content: "\e9f0";
}
.nc-icon-mini.weather_sun-cloud-snow-55:before {
    content: "\e9f1";
}
.nc-icon-mini.weather_sun-cloud:before {
    content: "\e9f2";
}
.nc-icon-mini.weather_sun-fog-30:before {
    content: "\e9f3";
}
.nc-icon-mini.weather_sun-fog-43:before {
    content: "\e9f4";
}
.nc-icon-mini.weather_wind:before {
    content: "\e9f5";
}
.nc-icon-mini.weather-e_cloud-01:before {
    content: "\e9f6";
}
.nc-icon-mini.weather-e_cloud-02:before {
    content: "\e9f7";
}
.nc-icon-mini.users_add-27:before {
    content: "\e9f8";
}
.nc-icon-mini.users_add-29:before {
    content: "\e9f9";
}
.nc-icon-mini.users_badge-13:before {
    content: "\e9fa";
}
.nc-icon-mini.users_badge-14:before {
    content: "\e9fb";
}
.nc-icon-mini.users_badge-15:before {
    content: "\e9fc";
}
.nc-icon-mini.users_circle-08:before {
    content: "\e9fd";
}
.nc-icon-mini.users_circle-09:before {
    content: "\e9fe";
}
.nc-icon-mini.users_circle-10:before {
    content: "\e9ff";
}
.nc-icon-mini.users_delete-28:before {
    content: "\ea00";
}
.nc-icon-mini.users_delete-30:before {
    content: "\ea01";
}
.nc-icon-mini.users_man-20:before {
    content: "\ea02";
}
.nc-icon-mini.users_multiple-11:before {
    content: "\ea03";
}
.nc-icon-mini.users_multiple-19:before {
    content: "\ea04";
}
.nc-icon-mini.users_single-01:before {
    content: "\ea05";
}
.nc-icon-mini.users_single-02:before {
    content: "\ea06";
}
.nc-icon-mini.users_single-03:before {
    content: "\ea07";
}
.nc-icon-mini.users_single-04:before {
    content: "\ea08";
}
.nc-icon-mini.users_single-05:before {
    content: "\ea09";
}
.nc-icon-mini.users_single-body:before {
    content: "\ea0a";
}
.nc-icon-mini.users_square-31:before {
    content: "\ea0b";
}
.nc-icon-mini.users_square-32:before {
    content: "\ea0c";
}
.nc-icon-mini.users_square-33:before {
    content: "\ea0d";
}
.nc-icon-mini.users_woman-21:before {
    content: "\ea0e";
}
.nc-icon-mini.travel_backpack:before {
    content: "\eab9";
}
.nc-icon-mini.travel_bag:before {
    content: "\eaba";
}
.nc-icon-mini.travel_camping:before {
    content: "\eabb";
}
.nc-icon-mini.travel_drink:before {
    content: "\eabc";
}
.nc-icon-mini.travel_explore:before {
    content: "\eabd";
}
.nc-icon-mini.travel_fire:before {
    content: "\eabe";
}
.nc-icon-mini.travel_hotel-bell:before {
    content: "\eabf";
}
.nc-icon-mini.travel_hotel-symbol:before {
    content: "\eac0";
}
.nc-icon-mini.travel_hotel:before {
    content: "\eac1";
}
.nc-icon-mini.travel_igloo:before {
    content: "\eac2";
}
.nc-icon-mini.travel_jellyfish:before {
    content: "\eac3";
}
.nc-icon-mini.travel_lamp:before {
    content: "\eac4";
}
.nc-icon-mini.travel_luggage:before {
    content: "\eac5";
}
.nc-icon-mini.travel_octopus:before {
    content: "\eac6";
}
.nc-icon-mini.travel_passport:before {
    content: "\eac7";
}
.nc-icon-mini.travel_pickaxe:before {
    content: "\eac8";
}
.nc-icon-mini.travel_pool:before {
    content: "\eac9";
}
.nc-icon-mini.travel_rackets:before {
    content: "\eaca";
}
.nc-icon-mini.travel_road-sign-left:before {
    content: "\eacb";
}
.nc-icon-mini.travel_road-sign-right:before {
    content: "\eacc";
}
.nc-icon-mini.travel_rowing:before {
    content: "\eacd";
}
.nc-icon-mini.travel_sea-mask:before {
    content: "\eace";
}
.nc-icon-mini.travel_shark:before {
    content: "\eacf";
}
.nc-icon-mini.travel_spa:before {
    content: "\ead0";
}
.nc-icon-mini.travel_sunglasses:before {
    content: "\ead1";
}
.nc-icon-mini.travel_surf:before {
    content: "\ead2";
}
.nc-icon-mini.travel_swimwear:before {
    content: "\ead3";
}
.nc-icon-mini.travel_swiss-knife:before {
    content: "\ead4";
}
.nc-icon-mini.travel_trolley:before {
    content: "\ead5";
}
.nc-icon-mini.travel_world:before {
    content: "\ead6";
}
.nc-icon-mini.transportation_air-baloon:before {
    content: "\ead7";
}
.nc-icon-mini.transportation_bike:before {
    content: "\ead8";
}
.nc-icon-mini.transportation_boat-small-02:before {
    content: "\ead9";
}
.nc-icon-mini.transportation_boat-small-03:before {
    content: "\eada";
}
.nc-icon-mini.transportation_boat:before {
    content: "\eadb";
}
.nc-icon-mini.transportation_bus-front-10:before {
    content: "\eadc";
}
.nc-icon-mini.transportation_bus-front-12:before {
    content: "\eadd";
}
.nc-icon-mini.transportation_car-front:before {
    content: "\eade";
}
.nc-icon-mini.transportation_car-taxi:before {
    content: "\eadf";
}
.nc-icon-mini.transportation_car:before {
    content: "\eae0";
}
.nc-icon-mini.transportation_helicopter:before {
    content: "\eae1";
}
.nc-icon-mini.transportation_helmet:before {
    content: "\eae2";
}
.nc-icon-mini.transportation_light-traffic:before {
    content: "\eae3";
}
.nc-icon-mini.transportation_moto:before {
    content: "\eae4";
}
.nc-icon-mini.transportation_plane-17:before {
    content: "\eae5";
}
.nc-icon-mini.transportation_plane-18:before {
    content: "\eae6";
}
.nc-icon-mini.transportation_road:before {
    content: "\eae7";
}
.nc-icon-mini.transportation_skateboard:before {
    content: "\eae8";
}
.nc-icon-mini.transportation_tractor:before {
    content: "\eae9";
}
.nc-icon-mini.transportation_train:before {
    content: "\eaea";
}
.nc-icon-mini.transportation_tram:before {
    content: "\eaeb";
}
.nc-icon-mini.transportation_truck-front:before {
    content: "\eaec";
}
.nc-icon-mini.transportation_vespa-front:before {
    content: "\eaed";
}
.nc-icon-mini.gestures_2x-drag-down:before {
    content: "\eaee";
}
.nc-icon-mini.gestures_2x-drag-up:before {
    content: "\eaef";
}
.nc-icon-mini.gestures_active-38:before {
    content: "\eaf0";
}
.nc-icon-mini.gestures_active-40:before {
    content: "\eaf1";
}
.nc-icon-mini.gestures_camera:before {
    content: "\eaf2";
}
.nc-icon-mini.gestures_double-tap:before {
    content: "\eaf3";
}
.nc-icon-mini.gestures_drag-21:before {
    content: "\eaf4";
}
.nc-icon-mini.gestures_drag-31:before {
    content: "\eaf5";
}
.nc-icon-mini.gestures_drag-down:before {
    content: "\eaf6";
}
.nc-icon-mini.gestures_drag-left:before {
    content: "\eaf7";
}
.nc-icon-mini.gestures_drag-right:before {
    content: "\eaf8";
}
.nc-icon-mini.gestures_drag-up:before {
    content: "\eaf9";
}
.nc-icon-mini.gestures_grab:before {
    content: "\eafa";
}
.nc-icon-mini.gestures_pin:before {
    content: "\eafb";
}
.nc-icon-mini.gestures_pinch:before {
    content: "\eafc";
}
.nc-icon-mini.gestures_rotate-22:before {
    content: "\eafd";
}
.nc-icon-mini.gestures_scan:before {
    content: "\eafe";
}
.nc-icon-mini.gestures_stretch:before {
    content: "\eaff";
}
.nc-icon-mini.gestures_swipe-bottom:before {
    content: "\eb00";
}
.nc-icon-mini.gestures_swipe-left:before {
    content: "\eb01";
}
.nc-icon-mini.gestures_swipe-right:before {
    content: "\eb02";
}
.nc-icon-mini.gestures_swipe-up:before {
    content: "\eb03";
}
.nc-icon-mini.gestures_tap-01:before {
    content: "\eb04";
}
.nc-icon-mini.gestures_tap-02:before {
    content: "\eb05";
}
.nc-icon-mini.text_align-center:before {
    content: "\eb06";
}
.nc-icon-mini.text_align-justify:before {
    content: "\eb07";
}
.nc-icon-mini.text_align-left:before {
    content: "\eb08";
}
.nc-icon-mini.text_align-right:before {
    content: "\eb09";
}
.nc-icon-mini.text_background:before {
    content: "\eb0a";
}
.nc-icon-mini.text_bold:before {
    content: "\eb0b";
}
.nc-icon-mini.text_capitalize:before {
    content: "\eb0c";
}
.nc-icon-mini.text_caps-small:before {
    content: "\eb0d";
}
.nc-icon-mini.text_color:before {
    content: "\eb0e";
}
.nc-icon-mini.text_edit:before {
    content: "\eb0f";
}
.nc-icon-mini.text_italic:before {
    content: "\eb10";
}
.nc-icon-mini.text_line-height:before {
    content: "\eb11";
}
.nc-icon-mini.text_list-bullet:before {
    content: "\eb12";
}
.nc-icon-mini.text_list-numbers:before {
    content: "\eb13";
}
.nc-icon-mini.text_margin-left:before {
    content: "\eb14";
}
.nc-icon-mini.text_margin-right:before {
    content: "\eb15";
}
.nc-icon-mini.text_quote:before {
    content: "\eb16";
}
.nc-icon-mini.text_scale-horizontal:before {
    content: "\eb17";
}
.nc-icon-mini.text_scale-vertical:before {
    content: "\eb18";
}
.nc-icon-mini.text_size:before {
    content: "\eb19";
}
.nc-icon-mini.text_strikethrough:before {
    content: "\eb1a";
}
.nc-icon-mini.text_subscript:before {
    content: "\eb1b";
}
.nc-icon-mini.text_superscript:before {
    content: "\eb1c";
}
.nc-icon-mini.text_tracking:before {
    content: "\eb1d";
}
.nc-icon-mini.text_underline:before {
    content: "\eb1e";
}
.nc-icon-mini.tech_cable-49:before {
    content: "\eb1f";
}
.nc-icon-mini.tech_cd-reader:before {
    content: "\eb20";
}
.nc-icon-mini.tech_computer-monitor:before {
    content: "\eb21";
}
.nc-icon-mini.tech_computer-old:before {
    content: "\eb22";
}
.nc-icon-mini.tech_computer:before {
    content: "\eb23";
}
.nc-icon-mini.tech_controller-modern:before {
    content: "\eb24";
}
.nc-icon-mini.tech_controller:before {
    content: "\eb25";
}
.nc-icon-mini.tech_desktop-screen:before {
    content: "\eb26";
}
.nc-icon-mini.tech_desktop:before {
    content: "\eb27";
}
.nc-icon-mini.tech_disk-reader:before {
    content: "\eb28";
}
.nc-icon-mini.tech_disk:before {
    content: "\eb29";
}
.nc-icon-mini.tech_headphones:before {
    content: "\eb2a";
}
.nc-icon-mini.tech_keyboard-wifi:before {
    content: "\eb2b";
}
.nc-icon-mini.tech_keyboard:before {
    content: "\eb2c";
}
.nc-icon-mini.tech_laptop-camera:before {
    content: "\eb2d";
}
.nc-icon-mini.tech_laptop-front:before {
    content: "\eb2e";
}
.nc-icon-mini.tech_laptop:before {
    content: "\eb2f";
}
.nc-icon-mini.tech_mobile-button:before {
    content: "\eb30";
}
.nc-icon-mini.tech_mobile-camera:before {
    content: "\eb31";
}
.nc-icon-mini.tech_mobile-recharger-08:before {
    content: "\eb32";
}
.nc-icon-mini.tech_mobile-recharger-09:before {
    content: "\eb33";
}
.nc-icon-mini.tech_mobile-toolbar:before {
    content: "\eb34";
}
.nc-icon-mini.tech_mobile:before {
    content: "\eb35";
}
.nc-icon-mini.tech_music:before {
    content: "\eb36";
}
.nc-icon-mini.tech_navigation:before {
    content: "\eb37";
}
.nc-icon-mini.tech_player-19:before {
    content: "\eb38";
}
.nc-icon-mini.tech_player-48:before {
    content: "\eb39";
}
.nc-icon-mini.tech_print-round:before {
    content: "\eb3a";
}
.nc-icon-mini.tech_print:before {
    content: "\eb3b";
}
.nc-icon-mini.tech_ram:before {
    content: "\eb3c";
}
.nc-icon-mini.tech_remote:before {
    content: "\eb3d";
}
.nc-icon-mini.tech_signal:before {
    content: "\eb3e";
}
.nc-icon-mini.tech_socket:before {
    content: "\eb3f";
}
.nc-icon-mini.tech_sync:before {
    content: "\eb40";
}
.nc-icon-mini.tech_tablet-button:before {
    content: "\eb41";
}
.nc-icon-mini.tech_tablet-reader-31:before {
    content: "\eb42";
}
.nc-icon-mini.tech_tablet-reader-42:before {
    content: "\eb43";
}
.nc-icon-mini.tech_tablet-toolbar:before {
    content: "\eb44";
}
.nc-icon-mini.tech_tablet:before {
    content: "\eb45";
}
.nc-icon-mini.tech_tv-old:before {
    content: "\eb46";
}
.nc-icon-mini.tech_tv:before {
    content: "\eb47";
}
.nc-icon-mini.tech_watch-circle:before {
    content: "\eb48";
}
.nc-icon-mini.tech_watch-time:before {
    content: "\eb49";
}
.nc-icon-mini.tech_watch:before {
    content: "\eb4a";
}
.nc-icon-mini.tech_webcam-38:before {
    content: "\eb4b";
}
.nc-icon-mini.tech_webcam-39:before {
    content: "\eb4c";
}
.nc-icon-mini.tech_wifi-router:before {
    content: "\eb4d";
}
.nc-icon-mini.tech_wifi:before {
    content: "\eb4e";
}
.nc-icon-mini.sport_badminton:before {
    content: "\eb4f";
}
.nc-icon-mini.sport_baseball-ball:before {
    content: "\eb50";
}
.nc-icon-mini.sport_baseball-bat:before {
    content: "\eb51";
}
.nc-icon-mini.sport_basketball-12:before {
    content: "\eb52";
}
.nc-icon-mini.sport_boxing:before {
    content: "\eb53";
}
.nc-icon-mini.sport_cardio:before {
    content: "\eb54";
}
.nc-icon-mini.sport_cricket:before {
    content: "\eb55";
}
.nc-icon-mini.sport_crown:before {
    content: "\eb56";
}
.nc-icon-mini.sport_dart:before {
    content: "\eb57";
}
.nc-icon-mini.sport_dumbbells:before {
    content: "\eb58";
}
.nc-icon-mini.sport_fencing:before {
    content: "\eb59";
}
.nc-icon-mini.sport_fishing:before {
    content: "\eb5a";
}
.nc-icon-mini.sport_flag-finish:before {
    content: "\eb5b";
}
.nc-icon-mini.sport_football-headguard:before {
    content: "\eb5c";
}
.nc-icon-mini.sport_golf:before {
    content: "\eb5d";
}
.nc-icon-mini.sport_helmet:before {
    content: "\eb5e";
}
.nc-icon-mini.sport_hockey:before {
    content: "\eb5f";
}
.nc-icon-mini.sport_kettlebell:before {
    content: "\eb60";
}
.nc-icon-mini.sport_ping-pong:before {
    content: "\eb61";
}
.nc-icon-mini.sport_podium:before {
    content: "\eb62";
}
.nc-icon-mini.sport_rope:before {
    content: "\eb63";
}
.nc-icon-mini.sport_rugby:before {
    content: "\eb64";
}
.nc-icon-mini.sport_shaker:before {
    content: "\eb65";
}
.nc-icon-mini.sport_skateboard:before {
    content: "\eb66";
}
.nc-icon-mini.sport_snowboard:before {
    content: "\eb67";
}
.nc-icon-mini.sport_soccer-field:before {
    content: "\eb68";
}
.nc-icon-mini.sport_steering-wheel:before {
    content: "\eb69";
}
.nc-icon-mini.sport_supplement:before {
    content: "\eb6a";
}
.nc-icon-mini.sport_surf:before {
    content: "\eb6b";
}
.nc-icon-mini.sport_tactic:before {
    content: "\eb6c";
}
.nc-icon-mini.sport_tennis-ball:before {
    content: "\eb6d";
}
.nc-icon-mini.sport_tennis:before {
    content: "\eb6e";
}
.nc-icon-mini.sport_trophy:before {
    content: "\eb6f";
}
.nc-icon-mini.sport_user-run:before {
    content: "\eb70";
}
.nc-icon-mini.sport_volleyball:before {
    content: "\eb71";
}
.nc-icon-mini.sport_whistle:before {
    content: "\eb72";
}
.nc-icon-mini.shopping_award:before {
    content: "\eb9f";
}
.nc-icon-mini.shopping_bag-09:before {
    content: "\eba0";
}
.nc-icon-mini.shopping_bag-16:before {
    content: "\eba1";
}
.nc-icon-mini.shopping_bag-17:before {
    content: "\eba2";
}
.nc-icon-mini.shopping_bag-20:before {
    content: "\eba3";
}
.nc-icon-mini.shopping_bag-add-18:before {
    content: "\eba4";
}
.nc-icon-mini.shopping_bag-add-21:before {
    content: "\eba5";
}
.nc-icon-mini.shopping_bag-remove-19:before {
    content: "\eba6";
}
.nc-icon-mini.shopping_bag-remove-22:before {
    content: "\eba7";
}
.nc-icon-mini.shopping_barcode:before {
    content: "\eba8";
}
.nc-icon-mini.shopping_bardcode-qr:before {
    content: "\eba9";
}
.nc-icon-mini.shopping_basket-simple-add:before {
    content: "\ebaa";
}
.nc-icon-mini.shopping_basket-simple-remove:before {
    content: "\ebab";
}
.nc-icon-mini.shopping_basket-simple:before {
    content: "\ebac";
}
.nc-icon-mini.shopping_basket:before {
    content: "\ebad";
}
.nc-icon-mini.shopping_bitcoin:before {
    content: "\ebae";
}
.nc-icon-mini.shopping_box-ribbon:before {
    content: "\ebaf";
}
.nc-icon-mini.shopping_box:before {
    content: "\ebb0";
}
.nc-icon-mini.shopping_cart-modern-in:before {
    content: "\ebb1";
}
.nc-icon-mini.shopping_cart-modern:before {
    content: "\ebb2";
}
.nc-icon-mini.shopping_cart-simple-add:before {
    content: "\ebb3";
}
.nc-icon-mini.shopping_cart-simple-in:before {
    content: "\ebb4";
}
.nc-icon-mini.shopping_cart-simple-remove:before {
    content: "\ebb5";
}
.nc-icon-mini.shopping_cart-simple:before {
    content: "\ebb6";
}
.nc-icon-mini.shopping_cart:before {
    content: "\ebb7";
}
.nc-icon-mini.shopping_cash-register:before {
    content: "\ebb8";
}
.nc-icon-mini.shopping_chart:before {
    content: "\ebb9";
}
.nc-icon-mini.shopping_credit-card-in:before {
    content: "\ebba";
}
.nc-icon-mini.shopping_credit-card:before {
    content: "\ebbb";
}
.nc-icon-mini.shopping_delivery-fast:before {
    content: "\ebbc";
}
.nc-icon-mini.shopping_discount:before {
    content: "\ebbd";
}
.nc-icon-mini.shopping_gift:before {
    content: "\ebbe";
}
.nc-icon-mini.shopping_list:before {
    content: "\ebbf";
}
.nc-icon-mini.shopping_newsletter:before {
    content: "\ebc0";
}
.nc-icon-mini.shopping_receipt-list-42:before {
    content: "\ebc1";
}
.nc-icon-mini.shopping_receipt-list-43:before {
    content: "\ebc2";
}
.nc-icon-mini.shopping_receipt:before {
    content: "\ebc3";
}
.nc-icon-mini.shopping_shop:before {
    content: "\ebc4";
}
.nc-icon-mini.shopping_stock:before {
    content: "\ebc5";
}
.nc-icon-mini.shopping_tag-content:before {
    content: "\ebc6";
}
.nc-icon-mini.shopping_tag-cut:before {
    content: "\ebc7";
}
.nc-icon-mini.shopping_tag:before {
    content: "\ebc8";
}
.nc-icon-mini.shopping_wallet:before {
    content: "\ebc9";
}
.nc-icon-mini.education_agenda-bookmark:before {
    content: "\ebca";
}
.nc-icon-mini.education_atom:before {
    content: "\ebcb";
}
.nc-icon-mini.education_award-55:before {
    content: "\ebcc";
}
.nc-icon-mini.education_backpack-57:before {
    content: "\ebcd";
}
.nc-icon-mini.education_backpack-58:before {
    content: "\ebce";
}
.nc-icon-mini.education_ball-basket:before {
    content: "\ebcf";
}
.nc-icon-mini.education_ball-soccer:before {
    content: "\ebd0";
}
.nc-icon-mini.education_board-51:before {
    content: "\ebd1";
}
.nc-icon-mini.education_book-39:before {
    content: "\ebd2";
}
.nc-icon-mini.education_book-bookmark:before {
    content: "\ebd3";
}
.nc-icon-mini.education_book-open:before {
    content: "\ebd4";
}
.nc-icon-mini.education_books-46:before {
    content: "\ebd5";
}
.nc-icon-mini.education_flask:before {
    content: "\ebd6";
}
.nc-icon-mini.education_glasses:before {
    content: "\ebd7";
}
.nc-icon-mini.education_hat:before {
    content: "\ebd8";
}
.nc-icon-mini.education_language:before {
    content: "\ebd9";
}
.nc-icon-mini.education_molecule:before {
    content: "\ebda";
}
.nc-icon-mini.education_notepad:before {
    content: "\ebdb";
}
.nc-icon-mini.education_paper-diploma:before {
    content: "\ebdc";
}
.nc-icon-mini.education_paper:before {
    content: "\ebdd";
}
.nc-icon-mini.education_pencil-47:before {
    content: "\ebde";
}
.nc-icon-mini.education_school:before {
    content: "\ebdf";
}
.nc-icon-mini.objects_alien-29:before {
    content: "\ebe0";
}
.nc-icon-mini.objects_alien-33:before {
    content: "\ebe1";
}
.nc-icon-mini.objects_anchor:before {
    content: "\ebe2";
}
.nc-icon-mini.objects_astronaut:before {
    content: "\ebe3";
}
.nc-icon-mini.objects_axe:before {
    content: "\ebe4";
}
.nc-icon-mini.objects_baby-bottle:before {
    content: "\ebe5";
}
.nc-icon-mini.objects_baby:before {
    content: "\ebe6";
}
.nc-icon-mini.objects_baloon:before {
    content: "\ebe7";
}
.nc-icon-mini.objects_battery:before {
    content: "\ebe8";
}
.nc-icon-mini.objects_bear:before {
    content: "\ebe9";
}
.nc-icon-mini.objects_billiard:before {
    content: "\ebea";
}
.nc-icon-mini.objects_binocular:before {
    content: "\ebeb";
}
.nc-icon-mini.objects_bow:before {
    content: "\ebec";
}
.nc-icon-mini.objects_bowling:before {
    content: "\ebed";
}
.nc-icon-mini.objects_broom:before {
    content: "\ebee";
}
.nc-icon-mini.objects_cone:before {
    content: "\ebef";
}
.nc-icon-mini.objects_controller:before {
    content: "\ebf0";
}
.nc-icon-mini.objects_diamond:before {
    content: "\ebf1";
}
.nc-icon-mini.objects_dice:before {
    content: "\ebf2";
}
.nc-icon-mini.objects_globe:before {
    content: "\ebf3";
}
.nc-icon-mini.objects_hut:before {
    content: "\ebf4";
}
.nc-icon-mini.objects_key-25:before {
    content: "\ebf5";
}
.nc-icon-mini.objects_key-26:before {
    content: "\ebf6";
}
.nc-icon-mini.objects_lamp:before {
    content: "\ebf7";
}
.nc-icon-mini.objects_leaf-36:before {
    content: "\ebf8";
}
.nc-icon-mini.objects_light:before {
    content: "\ebf9";
}
.nc-icon-mini.objects_planet:before {
    content: "\ebfa";
}
.nc-icon-mini.objects_puzzle-09:before {
    content: "\ebfb";
}
.nc-icon-mini.objects_puzzle-10:before {
    content: "\ebfc";
}
.nc-icon-mini.objects_shovel:before {
    content: "\ebfd";
}
.nc-icon-mini.objects_skull:before {
    content: "\ebfe";
}
.nc-icon-mini.objects_spaceship:before {
    content: "\ebff";
}
.nc-icon-mini.objects_support-16:before {
    content: "\ec00";
}
.nc-icon-mini.objects_support-17:before {
    content: "\ec01";
}
.nc-icon-mini.objects_umbrella-13:before {
    content: "\ec02";
}
.nc-icon-mini.objects_umbrella-14:before {
    content: "\ec03";
}
.nc-icon-mini.objects_wool-ball:before {
    content: "\ec04";
}
.nc-icon-mini.media-1_3d:before {
    content: "\ec05";
}
.nc-icon-mini.media-1_album:before {
    content: "\ec06";
}
.nc-icon-mini.media-1_audio-91:before {
    content: "\ec07";
}
.nc-icon-mini.media-1_audio-92:before {
    content: "\ec08";
}
.nc-icon-mini.media-1_balance:before {
    content: "\ec09";
}
.nc-icon-mini.media-1_brightness-46:before {
    content: "\ec0a";
}
.nc-icon-mini.media-1_brightness-47:before {
    content: "\ec0b";
}
.nc-icon-mini.media-1_button-eject:before {
    content: "\ec0c";
}
.nc-icon-mini.media-1_button-next:before {
    content: "\ec0d";
}
.nc-icon-mini.media-1_button-pause:before {
    content: "\ec0e";
}
.nc-icon-mini.media-1_button-play:before {
    content: "\ec0f";
}
.nc-icon-mini.media-1_button-power:before {
    content: "\ec10";
}
.nc-icon-mini.media-1_button-previous:before {
    content: "\ec11";
}
.nc-icon-mini.media-1_button-record:before {
    content: "\ec12";
}
.nc-icon-mini.media-1_button-rewind:before {
    content: "\ec13";
}
.nc-icon-mini.media-1_button-skip:before {
    content: "\ec14";
}
.nc-icon-mini.media-1_button-stop:before {
    content: "\ec15";
}
.nc-icon-mini.media-1_camera-18:before {
    content: "\ec16";
}
.nc-icon-mini.media-1_camera-19:before {
    content: "\ec17";
}
.nc-icon-mini.media-1_camera-20:before {
    content: "\ec18";
}
.nc-icon-mini.media-1_camera-ban-37:before {
    content: "\ec19";
}
.nc-icon-mini.media-1_camera-compact:before {
    content: "\ec1a";
}
.nc-icon-mini.media-1_camera-square-57:before {
    content: "\ec1b";
}
.nc-icon-mini.media-1_camera-time:before {
    content: "\ec1c";
}
.nc-icon-mini.media-1_countdown-34:before {
    content: "\ec1d";
}
.nc-icon-mini.media-1_edit-color:before {
    content: "\ec1e";
}
.nc-icon-mini.media-1_edit-contrast-42:before {
    content: "\ec1f";
}
.nc-icon-mini.media-1_edit-contrast-43:before {
    content: "\ec20";
}
.nc-icon-mini.media-1_edit-saturation:before {
    content: "\ec21";
}
.nc-icon-mini.media-1_flash-21:before {
    content: "\ec22";
}
.nc-icon-mini.media-1_flash-24:before {
    content: "\ec23";
}
.nc-icon-mini.media-1_flash-29:before {
    content: "\ec24";
}
.nc-icon-mini.media-1_focus-32:before {
    content: "\ec25";
}
.nc-icon-mini.media-1_focus-38:before {
    content: "\ec26";
}
.nc-icon-mini.media-1_focus-40:before {
    content: "\ec27";
}
.nc-icon-mini.media-1_focus-circle:before {
    content: "\ec28";
}
.nc-icon-mini.media-1_frame-12:before {
    content: "\ec29";
}
.nc-icon-mini.media-1_frame-41:before {
    content: "\ec2a";
}
.nc-icon-mini.media-1_grid:before {
    content: "\ec2b";
}
.nc-icon-mini.media-1_image-01:before {
    content: "\ec2c";
}
.nc-icon-mini.media-1_image-02:before {
    content: "\ec2d";
}
.nc-icon-mini.media-1_image-05:before {
    content: "\ec2e";
}
.nc-icon-mini.media-1_layers:before {
    content: "\ec2f";
}
.nc-icon-mini.media-1_lens-31:before {
    content: "\ec30";
}
.nc-icon-mini.media-1_lens-56:before {
    content: "\ec31";
}
.nc-icon-mini.media-1_macro:before {
    content: "\ec32";
}
.nc-icon-mini.media-1_movie-61:before {
    content: "\ec33";
}
.nc-icon-mini.media-1_movie-62:before {
    content: "\ec34";
}
.nc-icon-mini.media-1_night:before {
    content: "\ec35";
}
.nc-icon-mini.media-1_picture:before {
    content: "\ec36";
}
.nc-icon-mini.media-1_play-68:before {
    content: "\ec37";
}
.nc-icon-mini.media-1_play-69:before {
    content: "\ec38";
}
.nc-icon-mini.media-1_player:before {
    content: "\ec39";
}
.nc-icon-mini.media-1_polaroid-add:before {
    content: "\ec3a";
}
.nc-icon-mini.media-1_polaroid-delete:before {
    content: "\ec3b";
}
.nc-icon-mini.media-1_polaroid-multiple:before {
    content: "\ec3c";
}
.nc-icon-mini.media-1_polaroid:before {
    content: "\ec3d";
}
.nc-icon-mini.media-1_roll:before {
    content: "\ec3e";
}
.nc-icon-mini.media-1_sd:before {
    content: "\ec3f";
}
.nc-icon-mini.media-1_ticket-75:before {
    content: "\ec40";
}
.nc-icon-mini.media-1_ticket-76:before {
    content: "\ec41";
}
.nc-icon-mini.media-1_touch:before {
    content: "\ec42";
}
.nc-icon-mini.media-1_tripod:before {
    content: "\ec43";
}
.nc-icon-mini.media-1_video-64:before {
    content: "\ec44";
}
.nc-icon-mini.media-1_video-65:before {
    content: "\ec45";
}
.nc-icon-mini.media-1_video-66:before {
    content: "\ec46";
}
.nc-icon-mini.media-1_video-67:before {
    content: "\ec47";
}
.nc-icon-mini.media-1_videocamera-71:before {
    content: "\ec48";
}
.nc-icon-mini.media-1_videocamera-72:before {
    content: "\ec49";
}
.nc-icon-mini.media-1_volume-93:before {
    content: "\ec4a";
}
.nc-icon-mini.media-1_volume-97:before {
    content: "\ec4b";
}
.nc-icon-mini.media-1_volume-down:before {
    content: "\ec4c";
}
.nc-icon-mini.media-1_volume-up:before {
    content: "\ec4d";
}
.nc-icon-mini.media-2_headphones-mic:before {
    content: "\ec4e";
}
.nc-icon-mini.media-2_headphones:before {
    content: "\ec4f";
}
.nc-icon-mini.media-2_knob:before {
    content: "\ec50";
}
.nc-icon-mini.media-2_mic:before {
    content: "\ec51";
}
.nc-icon-mini.media-2_music-album:before {
    content: "\ec52";
}
.nc-icon-mini.media-2_note-03:before {
    content: "\ec53";
}
.nc-icon-mini.media-2_note-04:before {
    content: "\ec54";
}
.nc-icon-mini.media-2_radio:before {
    content: "\ec55";
}
.nc-icon-mini.media-2_sound-wave:before {
    content: "\ec56";
}
.nc-icon-mini.media-2_speaker-01:before {
    content: "\ec57";
}
.nc-icon-mini.media-2_speaker-05:before {
    content: "\ec58";
}
.nc-icon-mini.media-2_tape:before {
    content: "\ec59";
}
.nc-icon-mini.location_appointment:before {
    content: "\ec5a";
}
.nc-icon-mini.location_bookmark-add:before {
    content: "\ec5b";
}
.nc-icon-mini.location_bookmark-remove:before {
    content: "\ec5c";
}
.nc-icon-mini.location_bookmark:before {
    content: "\ec5d";
}
.nc-icon-mini.location_compass-04:before {
    content: "\ec5e";
}
.nc-icon-mini.location_compass-05:before {
    content: "\ec5f";
}
.nc-icon-mini.location_compass-06:before {
    content: "\ec60";
}
.nc-icon-mini.location_crosshair:before {
    content: "\ec61";
}
.nc-icon-mini.location_explore:before {
    content: "\ec62";
}
.nc-icon-mini.location_flag-diagonal-33:before {
    content: "\ec63";
}
.nc-icon-mini.location_flag-diagonal-34:before {
    content: "\ec64";
}
.nc-icon-mini.location_flag-points-31:before {
    content: "\ec65";
}
.nc-icon-mini.location_flag-points-32:before {
    content: "\ec66";
}
.nc-icon-mini.location_flag-simple:before {
    content: "\ec67";
}
.nc-icon-mini.location_flag-triangle:before {
    content: "\ec68";
}
.nc-icon-mini.location_flag:before {
    content: "\ec69";
}
.nc-icon-mini.location_gps:before {
    content: "\ec6a";
}
.nc-icon-mini.location_map:before {
    content: "\ec6b";
}
.nc-icon-mini.location_marker:before {
    content: "\ec6c";
}
.nc-icon-mini.location_pin-add:before {
    content: "\ec6d";
}
.nc-icon-mini.location_pin-copy:before {
    content: "\ec6e";
}
.nc-icon-mini.location_pin-remove:before {
    content: "\ec6f";
}
.nc-icon-mini.location_pin:before {
    content: "\ec70";
}
.nc-icon-mini.location_pins:before {
    content: "\ec71";
}
.nc-icon-mini.location_position-marker:before {
    content: "\ec72";
}
.nc-icon-mini.location_radar:before {
    content: "\ec73";
}
.nc-icon-mini.location_treasure-map-40:before {
    content: "\ec74";
}
.nc-icon-mini.location_world:before {
    content: "\ec75";
}
.nc-icon-mini.location-e_bookmark-add:before {
    content: "\ec76";
}
.nc-icon-mini.location-e_bookmark-remove:before {
    content: "\ec77";
}
.nc-icon-mini.location-e_flag-06:before {
    content: "\ec78";
}
.nc-icon-mini.location-e_flag-07:before {
    content: "\ec79";
}
.nc-icon-mini.location-e_gps:before {
    content: "\ec7a";
}
.nc-icon-mini.location-e_pin-add:before {
    content: "\ec7b";
}
.nc-icon-mini.location-e_pin-remove:before {
    content: "\ec7c";
}
.nc-icon-mini.location-e_pin:before {
    content: "\ec7d";
}
.nc-icon-mini.holidays_bat:before {
    content: "\ec7e";
}
.nc-icon-mini.holidays_bones:before {
    content: "\ec7f";
}
.nc-icon-mini.holidays_boot:before {
    content: "\ec80";
}
.nc-icon-mini.holidays_candy:before {
    content: "\ec81";
}
.nc-icon-mini.holidays_cat:before {
    content: "\ec82";
}
.nc-icon-mini.holidays_cauldron:before {
    content: "\ec83";
}
.nc-icon-mini.holidays_chimney:before {
    content: "\ec84";
}
.nc-icon-mini.holidays_cockade:before {
    content: "\ec85";
}
.nc-icon-mini.holidays_coffin:before {
    content: "\ec86";
}
.nc-icon-mini.holidays_deer:before {
    content: "\ec87";
}
.nc-icon-mini.holidays_egg-38:before {
    content: "\ec88";
}
.nc-icon-mini.holidays_egg-39:before {
    content: "\ec89";
}
.nc-icon-mini.holidays_ghost:before {
    content: "\ec8a";
}
.nc-icon-mini.holidays_gift:before {
    content: "\ec8b";
}
.nc-icon-mini.holidays_glove:before {
    content: "\ec8c";
}
.nc-icon-mini.holidays_grave:before {
    content: "\ec8d";
}
.nc-icon-mini.holidays_light:before {
    content: "\ec8e";
}
.nc-icon-mini.holidays_message:before {
    content: "\ec8f";
}
.nc-icon-mini.holidays_owl:before {
    content: "\ec90";
}
.nc-icon-mini.holidays_pumpkin:before {
    content: "\ec91";
}
.nc-icon-mini.holidays_rabbit:before {
    content: "\ec92";
}
.nc-icon-mini.holidays_santa-hat:before {
    content: "\ec93";
}
.nc-icon-mini.holidays_sickle:before {
    content: "\ec94";
}
.nc-icon-mini.holidays_snow-ball:before {
    content: "\ec95";
}
.nc-icon-mini.holidays_snowman:before {
    content: "\ec96";
}
.nc-icon-mini.holidays_soak:before {
    content: "\ec97";
}
.nc-icon-mini.holidays_spider:before {
    content: "\ec98";
}
.nc-icon-mini.holidays_tree-ball:before {
    content: "\ec99";
}
.nc-icon-mini.holidays_tree:before {
    content: "\ec9a";
}
.nc-icon-mini.holidays_vampire:before {
    content: "\ec9b";
}
.nc-icon-mini.holidays_witch-hat:before {
    content: "\ec9c";
}
.nc-icon-mini.holidays_wolf:before {
    content: "\ec9d";
}
.nc-icon-mini.holidays_zombie:before {
    content: "\ec9e";
}
.nc-icon-mini.health_apple:before {
    content: "\ec9f";
}
.nc-icon-mini.health_bag-49:before {
    content: "\eca0";
}
.nc-icon-mini.health_bag-50:before {
    content: "\eca1";
}
.nc-icon-mini.health_brain:before {
    content: "\eca2";
}
.nc-icon-mini.health_dna-27:before {
    content: "\eca3";
}
.nc-icon-mini.health_dna-38:before {
    content: "\eca4";
}
.nc-icon-mini.health_flask:before {
    content: "\eca5";
}
.nc-icon-mini.health_heartbeat-16:before {
    content: "\eca6";
}
.nc-icon-mini.health_height:before {
    content: "\eca7";
}
.nc-icon-mini.health_hospital-32:before {
    content: "\eca8";
}
.nc-icon-mini.health_hospital-33:before {
    content: "\eca9";
}
.nc-icon-mini.health_hospital-34:before {
    content: "\ecaa";
}
.nc-icon-mini.health_humidity-26:before {
    content: "\ecab";
}
.nc-icon-mini.health_humidity-52:before {
    content: "\ecac";
}
.nc-icon-mini.health_molecule-39:before {
    content: "\ecad";
}
.nc-icon-mini.health_notebook:before {
    content: "\ecae";
}
.nc-icon-mini.health_patch-46:before {
    content: "\ecaf";
}
.nc-icon-mini.health_pill-42:before {
    content: "\ecb0";
}
.nc-icon-mini.health_pill-43:before {
    content: "\ecb1";
}
.nc-icon-mini.health_pill-container-44:before {
    content: "\ecb2";
}
.nc-icon-mini.health_pill-container-47:before {
    content: "\ecb3";
}
.nc-icon-mini.health_pulse-chart:before {
    content: "\ecb4";
}
.nc-icon-mini.health_pulse-sleep:before {
    content: "\ecb5";
}
.nc-icon-mini.health_pulse:before {
    content: "\ecb6";
}
.nc-icon-mini.health_steps:before {
    content: "\ecb7";
}
.nc-icon-mini.health_syringe:before {
    content: "\ecb8";
}
.nc-icon-mini.health_temperature-23:before {
    content: "\ecb9";
}
.nc-icon-mini.health_temperature-24:before {
    content: "\ecba";
}
.nc-icon-mini.health_tooth:before {
    content: "\ecbb";
}
.nc-icon-mini.health_weed:before {
    content: "\ecbc";
}
.nc-icon-mini.health_weight:before {
    content: "\ecbd";
}
.nc-icon-mini.health_wheelchair:before {
    content: "\ecbe";
}
.nc-icon-mini.health_woman:before {
    content: "\ecbf";
}
.nc-icon-mini.furniture_air-conditioner:before {
    content: "\ecc0";
}
.nc-icon-mini.furniture_armchair:before {
    content: "\ecc1";
}
.nc-icon-mini.furniture_bed-09:before {
    content: "\ecc2";
}
.nc-icon-mini.furniture_bed-23:before {
    content: "\ecc3";
}
.nc-icon-mini.furniture_cabinet:before {
    content: "\ecc4";
}
.nc-icon-mini.furniture_cactus:before {
    content: "\ecc5";
}
.nc-icon-mini.furniture_chair:before {
    content: "\ecc6";
}
.nc-icon-mini.furniture_coat-hanger:before {
    content: "\ecc7";
}
.nc-icon-mini.furniture_coffee:before {
    content: "\ecc8";
}
.nc-icon-mini.furniture_cradle:before {
    content: "\ecc9";
}
.nc-icon-mini.furniture_curtain:before {
    content: "\ecca";
}
.nc-icon-mini.furniture_desk:before {
    content: "\eccb";
}
.nc-icon-mini.furniture_door:before {
    content: "\eccc";
}
.nc-icon-mini.furniture_drawer:before {
    content: "\eccd";
}
.nc-icon-mini.furniture_fridge:before {
    content: "\ecce";
}
.nc-icon-mini.furniture_hanger:before {
    content: "\eccf";
}
.nc-icon-mini.furniture_iron:before {
    content: "\ecd0";
}
.nc-icon-mini.furniture_lamp-floor:before {
    content: "\ecd1";
}
.nc-icon-mini.furniture_lamp:before {
    content: "\ecd2";
}
.nc-icon-mini.furniture_library:before {
    content: "\ecd3";
}
.nc-icon-mini.furniture_light:before {
    content: "\ecd4";
}
.nc-icon-mini.furniture_mixer:before {
    content: "\ecd5";
}
.nc-icon-mini.furniture_oven:before {
    content: "\ecd6";
}
.nc-icon-mini.furniture_shower:before {
    content: "\ecd7";
}
.nc-icon-mini.furniture_sink-wash:before {
    content: "\ecd8";
}
.nc-icon-mini.furniture_sink:before {
    content: "\ecd9";
}
.nc-icon-mini.furniture_storage-hanger:before {
    content: "\ecda";
}
.nc-icon-mini.furniture_storage:before {
    content: "\ecdb";
}
.nc-icon-mini.furniture_toilet-paper:before {
    content: "\ecdc";
}
.nc-icon-mini.furniture_toilet:before {
    content: "\ecdd";
}
.nc-icon-mini.furniture_tv:before {
    content: "\ecde";
}
.nc-icon-mini.furniture_wardrobe:before {
    content: "\ecdf";
}
.nc-icon-mini.furniture_wash:before {
    content: "\ece0";
}
.nc-icon-mini.food_baby:before {
    content: "\ece1";
}
.nc-icon-mini.food_bacon:before {
    content: "\ece2";
}
.nc-icon-mini.food_banana:before {
    content: "\ece3";
}
.nc-icon-mini.food_barbecue-tools:before {
    content: "\ece4";
}
.nc-icon-mini.food_beer-95:before {
    content: "\ece5";
}
.nc-icon-mini.food_beer-96:before {
    content: "\ece6";
}
.nc-icon-mini.food_beverage:before {
    content: "\ece7";
}
.nc-icon-mini.food_bottle-wine:before {
    content: "\ece8";
}
.nc-icon-mini.food_bottle:before {
    content: "\ece9";
}
.nc-icon-mini.food_bowl:before {
    content: "\ecea";
}
.nc-icon-mini.food_bread:before {
    content: "\eceb";
}
.nc-icon-mini.food_broccoli:before {
    content: "\ecec";
}
.nc-icon-mini.food_cake-13:before {
    content: "\eced";
}
.nc-icon-mini.food_cake-slice:before {
    content: "\ecee";
}
.nc-icon-mini.food_candle:before {
    content: "\ecef";
}
.nc-icon-mini.food_candy:before {
    content: "\ecf0";
}
.nc-icon-mini.food_carrot:before {
    content: "\ecf1";
}
.nc-icon-mini.food_champagne:before {
    content: "\ecf2";
}
.nc-icon-mini.food_cheese-24:before {
    content: "\ecf3";
}
.nc-icon-mini.food_cheese-87:before {
    content: "\ecf4";
}
.nc-icon-mini.food_cheeseburger:before {
    content: "\ecf5";
}
.nc-icon-mini.food_chef-hat:before {
    content: "\ecf6";
}
.nc-icon-mini.food_cherry:before {
    content: "\ecf7";
}
.nc-icon-mini.food_chicken:before {
    content: "\ecf8";
}
.nc-icon-mini.food_chili:before {
    content: "\ecf9";
}
.nc-icon-mini.food_chinese:before {
    content: "\ecfa";
}
.nc-icon-mini.food_chips:before {
    content: "\ecfb";
}
.nc-icon-mini.food_chocolate:before {
    content: "\ecfc";
}
.nc-icon-mini.food_cocktail:before {
    content: "\ecfd";
}
.nc-icon-mini.food_coffe-long:before {
    content: "\ecfe";
}
.nc-icon-mini.food_coffee-long:before {
    content: "\ecff";
}
.nc-icon-mini.food_coffee:before {
    content: "\ed00";
}
.nc-icon-mini.food_cookies:before {
    content: "\ed01";
}
.nc-icon-mini.food_course:before {
    content: "\ed02";
}
.nc-icon-mini.food_crab:before {
    content: "\ed03";
}
.nc-icon-mini.food_croissant:before {
    content: "\ed04";
}
.nc-icon-mini.food_cutlery-75:before {
    content: "\ed05";
}
.nc-icon-mini.food_cutlery-76:before {
    content: "\ed06";
}
.nc-icon-mini.food_cutlery-77:before {
    content: "\ed07";
}
.nc-icon-mini.food_donut:before {
    content: "\ed08";
}
.nc-icon-mini.food_drink:before {
    content: "\ed09";
}
.nc-icon-mini.food_egg:before {
    content: "\ed0a";
}
.nc-icon-mini.food_energy-drink:before {
    content: "\ed0b";
}
.nc-icon-mini.food_fish:before {
    content: "\ed0c";
}
.nc-icon-mini.food_glass:before {
    content: "\ed0d";
}
.nc-icon-mini.food_grape:before {
    content: "\ed0e";
}
.nc-icon-mini.food_hob:before {
    content: "\ed0f";
}
.nc-icon-mini.food_hot-dog:before {
    content: "\ed10";
}
.nc-icon-mini.food_ice-cream-22:before {
    content: "\ed11";
}
.nc-icon-mini.food_ice-cream-72:before {
    content: "\ed12";
}
.nc-icon-mini.food_kettle:before {
    content: "\ed13";
}
.nc-icon-mini.food_knife:before {
    content: "\ed14";
}
.nc-icon-mini.food_lighter:before {
    content: "\ed15";
}
.nc-icon-mini.food_matches:before {
    content: "\ed16";
}
.nc-icon-mini.food_measuring-cup:before {
    content: "\ed17";
}
.nc-icon-mini.food_microwave:before {
    content: "\ed18";
}
.nc-icon-mini.food_milk:before {
    content: "\ed19";
}
.nc-icon-mini.food_moka:before {
    content: "\ed1a";
}
.nc-icon-mini.food_muffin:before {
    content: "\ed1b";
}
.nc-icon-mini.food_mug:before {
    content: "\ed1c";
}
.nc-icon-mini.food_pan:before {
    content: "\ed1d";
}
.nc-icon-mini.food_pizza-slice:before {
    content: "\ed1e";
}
.nc-icon-mini.food_plate:before {
    content: "\ed1f";
}
.nc-icon-mini.food_pot:before {
    content: "\ed20";
}
.nc-icon-mini.food_recipe-book-46:before {
    content: "\ed21";
}
.nc-icon-mini.food_recipe-book-47:before {
    content: "\ed22";
}
.nc-icon-mini.food_rolling-pin:before {
    content: "\ed23";
}
.nc-icon-mini.food_sausage:before {
    content: "\ed24";
}
.nc-icon-mini.food_scale:before {
    content: "\ed25";
}
.nc-icon-mini.food_steak:before {
    content: "\ed26";
}
.nc-icon-mini.food_strawberry:before {
    content: "\ed27";
}
.nc-icon-mini.food_sushi:before {
    content: "\ed28";
}
.nc-icon-mini.food_tacos:before {
    content: "\ed29";
}
.nc-icon-mini.food_tea:before {
    content: "\ed2a";
}
.nc-icon-mini.food_watermelon:before {
    content: "\ed2b";
}
.nc-icon-mini.food_whisk:before {
    content: "\ed2c";
}
.nc-icon-mini.files_add:before {
    content: "\ed2d";
}
.nc-icon-mini.files_archive-3d-content:before {
    content: "\ed2e";
}
.nc-icon-mini.files_archive-check:before {
    content: "\ed2f";
}
.nc-icon-mini.files_archive-content:before {
    content: "\ed30";
}
.nc-icon-mini.files_archive-paper:before {
    content: "\ed31";
}
.nc-icon-mini.files_archive:before {
    content: "\ed32";
}
.nc-icon-mini.files_book-07:before {
    content: "\ed33";
}
.nc-icon-mini.files_box:before {
    content: "\ed34";
}
.nc-icon-mini.files_copy:before {
    content: "\ed35";
}
.nc-icon-mini.files_drawer:before {
    content: "\ed36";
}
.nc-icon-mini.files_folder-13:before {
    content: "\ed37";
}
.nc-icon-mini.files_folder-14:before {
    content: "\ed38";
}
.nc-icon-mini.files_folder-15:before {
    content: "\ed39";
}
.nc-icon-mini.files_folder-16:before {
    content: "\ed3a";
}
.nc-icon-mini.files_folder-17:before {
    content: "\ed3b";
}
.nc-icon-mini.files_folder-18:before {
    content: "\ed3c";
}
.nc-icon-mini.files_folder-add:before {
    content: "\ed3d";
}
.nc-icon-mini.files_folder-remove:before {
    content: "\ed3e";
}
.nc-icon-mini.files_notebook:before {
    content: "\ed3f";
}
.nc-icon-mini.files_paper:before {
    content: "\ed40";
}
.nc-icon-mini.files_remove:before {
    content: "\ed41";
}
.nc-icon-mini.files_single-content-02:before {
    content: "\ed42";
}
.nc-icon-mini.files_single-content-03:before {
    content: "\ed43";
}
.nc-icon-mini.files_single-copies:before {
    content: "\ed44";
}
.nc-icon-mini.files_single-copy-04:before {
    content: "\ed45";
}
.nc-icon-mini.files_single-copy-06:before {
    content: "\ed46";
}
.nc-icon-mini.files_single-folded-content:before {
    content: "\ed47";
}
.nc-icon-mini.files_single-folded:before {
    content: "\ed48";
}
.nc-icon-mini.files_single-paragraph:before {
    content: "\ed49";
}
.nc-icon-mini.files_single:before {
    content: "\ed4a";
}
.nc-icon-mini.files-e_add:before {
    content: "\ed4b";
}
.nc-icon-mini.files-e_book:before {
    content: "\ed4c";
}
.nc-icon-mini.files-e_folder-02:before {
    content: "\ed4d";
}
.nc-icon-mini.files-e_folder-03:before {
    content: "\ed4e";
}
.nc-icon-mini.files-e_folder-add:before {
    content: "\ed4f";
}
.nc-icon-mini.files-e_folder-remove:before {
    content: "\ed50";
}
.nc-icon-mini.files-e_remove:before {
    content: "\ed51";
}
.nc-icon-mini.emoticons_angry-10:before {
    content: "\ed52";
}
.nc-icon-mini.emoticons_angry-44:before {
    content: "\ed53";
}
.nc-icon-mini.emoticons_big-eyes:before {
    content: "\ed54";
}
.nc-icon-mini.emoticons_big-smile:before {
    content: "\ed55";
}
.nc-icon-mini.emoticons_bigmouth:before {
    content: "\ed56";
}
.nc-icon-mini.emoticons_bomb:before {
    content: "\ed57";
}
.nc-icon-mini.emoticons_cake:before {
    content: "\ed58";
}
.nc-icon-mini.emoticons_cry-15:before {
    content: "\ed59";
}
.nc-icon-mini.emoticons_cute:before {
    content: "\ed5a";
}
.nc-icon-mini.emoticons_devil:before {
    content: "\ed5b";
}
.nc-icon-mini.emoticons_fist:before {
    content: "\ed5c";
}
.nc-icon-mini.emoticons_ghost:before {
    content: "\ed5d";
}
.nc-icon-mini.emoticons_happy-sun:before {
    content: "\ed5e";
}
.nc-icon-mini.emoticons_kiss:before {
    content: "\ed5f";
}
.nc-icon-mini.emoticons_laugh-35:before {
    content: "\ed60";
}
.nc-icon-mini.emoticons_like-no:before {
    content: "\ed61";
}
.nc-icon-mini.emoticons_like:before {
    content: "\ed62";
}
.nc-icon-mini.emoticons_manga-62:before {
    content: "\ed63";
}
.nc-icon-mini.emoticons_manga-63:before {
    content: "\ed64";
}
.nc-icon-mini.emoticons_monster:before {
    content: "\ed65";
}
.nc-icon-mini.emoticons_nerd-22:before {
    content: "\ed66";
}
.nc-icon-mini.emoticons_poop:before {
    content: "\ed67";
}
.nc-icon-mini.emoticons_puzzled:before {
    content: "\ed68";
}
.nc-icon-mini.emoticons_quite-happy:before {
    content: "\ed69";
}
.nc-icon-mini.emoticons_robot:before {
    content: "\ed6a";
}
.nc-icon-mini.emoticons_sad:before {
    content: "\ed6b";
}
.nc-icon-mini.emoticons_satisfied:before {
    content: "\ed6c";
}
.nc-icon-mini.emoticons_shark:before {
    content: "\ed6d";
}
.nc-icon-mini.emoticons_shy:before {
    content: "\ed6e";
}
.nc-icon-mini.emoticons_skull:before {
    content: "\ed6f";
}
.nc-icon-mini.emoticons_smile:before {
    content: "\ed70";
}
.nc-icon-mini.emoticons_speechless:before {
    content: "\ed71";
}
.nc-icon-mini.emoticons_sunglasses-48:before {
    content: "\ed72";
}
.nc-icon-mini.emoticons_sunglasses-49:before {
    content: "\ed73";
}
.nc-icon-mini.emoticons_surprise:before {
    content: "\ed74";
}
.nc-icon-mini.emoticons_virus:before {
    content: "\ed75";
}
.nc-icon-mini.emoticons_what:before {
    content: "\ed76";
}
.nc-icon-mini.emoticons-e_angry-e-08:before {
    content: "\ed77";
}
.nc-icon-mini.emoticons-e_angry-e-17:before {
    content: "\ed78";
}
.nc-icon-mini.emoticons-e_ghost-e:before {
    content: "\ed79";
}
.nc-icon-mini.emoticons-e_kiss-e:before {
    content: "\ed7a";
}
.nc-icon-mini.emoticons-e_laugh-e:before {
    content: "\ed7b";
}
.nc-icon-mini.emoticons-e_monster-e:before {
    content: "\ed7c";
}
.nc-icon-mini.emoticons-e_puzzled-e:before {
    content: "\ed7d";
}
.nc-icon-mini.emoticons-e_quite-happy-e:before {
    content: "\ed7e";
}
.nc-icon-mini.emoticons-e_robot-e:before {
    content: "\ed7f";
}
.nc-icon-mini.emoticons-e_sad-e:before {
    content: "\ed80";
}
.nc-icon-mini.emoticons-e_satisfied-e:before {
    content: "\ed81";
}
.nc-icon-mini.emoticons-e_shark-e:before {
    content: "\ed82";
}
.nc-icon-mini.emoticons-e_shy-e:before {
    content: "\ed83";
}
.nc-icon-mini.emoticons-e_smile-e:before {
    content: "\ed84";
}
.nc-icon-mini.emoticons-e_speechless-e:before {
    content: "\ed85";
}
.nc-icon-mini.emoticons-e_surprise-e:before {
    content: "\ed86";
}
.nc-icon-mini.emoticons-e_what-e:before {
    content: "\ed87";
}
.nc-icon-mini.clothes_baby:before {
    content: "\eddc";
}
.nc-icon-mini.clothes_backpack:before {
    content: "\eddd";
}
.nc-icon-mini.clothes_bag-21:before {
    content: "\edde";
}
.nc-icon-mini.clothes_bag-22:before {
    content: "\eddf";
}
.nc-icon-mini.clothes_belt:before {
    content: "\ede0";
}
.nc-icon-mini.clothes_boot-woman:before {
    content: "\ede1";
}
.nc-icon-mini.clothes_boot:before {
    content: "\ede2";
}
.nc-icon-mini.clothes_bra:before {
    content: "\ede3";
}
.nc-icon-mini.clothes_button:before {
    content: "\ede4";
}
.nc-icon-mini.clothes_cap:before {
    content: "\ede5";
}
.nc-icon-mini.clothes_corset:before {
    content: "\ede6";
}
.nc-icon-mini.clothes_dress-woman:before {
    content: "\ede7";
}
.nc-icon-mini.clothes_flip:before {
    content: "\ede8";
}
.nc-icon-mini.clothes_glasses:before {
    content: "\ede9";
}
.nc-icon-mini.clothes_hat-top:before {
    content: "\edea";
}
.nc-icon-mini.clothes_hat:before {
    content: "\edeb";
}
.nc-icon-mini.clothes_iron:before {
    content: "\edec";
}
.nc-icon-mini.clothes_jeans-41:before {
    content: "\eded";
}
.nc-icon-mini.clothes_jeans-pocket:before {
    content: "\edee";
}
.nc-icon-mini.clothes_kitchen:before {
    content: "\edef";
}
.nc-icon-mini.clothes_long-sleeve:before {
    content: "\edf0";
}
.nc-icon-mini.clothes_makeup:before {
    content: "\edf1";
}
.nc-icon-mini.clothes_ring:before {
    content: "\edf2";
}
.nc-icon-mini.clothes_scarf:before {
    content: "\edf3";
}
.nc-icon-mini.clothes_shirt-buttons:before {
    content: "\edf4";
}
.nc-icon-mini.clothes_shirt-neck:before {
    content: "\edf5";
}
.nc-icon-mini.clothes_shirt:before {
    content: "\edf6";
}
.nc-icon-mini.clothes_shoe-man:before {
    content: "\edf7";
}
.nc-icon-mini.clothes_shoe-sport:before {
    content: "\edf8";
}
.nc-icon-mini.clothes_shoe-woman:before {
    content: "\edf9";
}
.nc-icon-mini.clothes_skirt:before {
    content: "\edfa";
}
.nc-icon-mini.clothes_slacks-12:before {
    content: "\edfb";
}
.nc-icon-mini.clothes_sock:before {
    content: "\edfc";
}
.nc-icon-mini.clothes_tie-bow:before {
    content: "\edfd";
}
.nc-icon-mini.clothes_tshirt-53:before {
    content: "\edfe";
}
.nc-icon-mini.clothes_tshirt-54:before {
    content: "\edff";
}
.nc-icon-mini.clothes_underwear-man:before {
    content: "\ee00";
}
.nc-icon-mini.clothes_underwear:before {
    content: "\ee01";
}
.nc-icon-mini.clothes_vest:before {
    content: "\ee02";
}
.nc-icon-mini.clothes_wash:before {
    content: "\ee03";
}
.nc-icon-mini.business_agenda:before {
    content: "\ee04";
}
.nc-icon-mini.business_atm:before {
    content: "\ee05";
}
.nc-icon-mini.business_award-48:before {
    content: "\ee06";
}
.nc-icon-mini.business_award-49:before {
    content: "\ee07";
}
.nc-icon-mini.business_award-74:before {
    content: "\ee08";
}
.nc-icon-mini.business_badge:before {
    content: "\ee09";
}
.nc-icon-mini.business_bank:before {
    content: "\ee0a";
}
.nc-icon-mini.business_board-27:before {
    content: "\ee0b";
}
.nc-icon-mini.business_board-28:before {
    content: "\ee0c";
}
.nc-icon-mini.business_books:before {
    content: "\ee0d";
}
.nc-icon-mini.business_briefcase-24:before {
    content: "\ee0e";
}
.nc-icon-mini.business_briefcase-25:before {
    content: "\ee0f";
}
.nc-icon-mini.business_briefcase-26:before {
    content: "\ee10";
}
.nc-icon-mini.business_building:before {
    content: "\ee11";
}
.nc-icon-mini.business_bulb-61:before {
    content: "\ee12";
}
.nc-icon-mini.business_bulb-62:before {
    content: "\ee13";
}
.nc-icon-mini.business_bulb-63:before {
    content: "\ee14";
}
.nc-icon-mini.business_businessman-03:before {
    content: "\ee15";
}
.nc-icon-mini.business_businessman-04:before {
    content: "\ee16";
}
.nc-icon-mini.business_calculator:before {
    content: "\ee17";
}
.nc-icon-mini.business_chair:before {
    content: "\ee18";
}
.nc-icon-mini.business_chart-bar-32:before {
    content: "\ee19";
}
.nc-icon-mini.business_chart-growth:before {
    content: "\ee1a";
}
.nc-icon-mini.business_chart-pie-35:before {
    content: "\ee1b";
}
.nc-icon-mini.business_chart-pie-36:before {
    content: "\ee1c";
}
.nc-icon-mini.business_chart:before {
    content: "\ee1d";
}
.nc-icon-mini.business_cheque:before {
    content: "\ee1e";
}
.nc-icon-mini.business_coins:before {
    content: "\ee1f";
}
.nc-icon-mini.business_connect:before {
    content: "\ee20";
}
.nc-icon-mini.business_contacts:before {
    content: "\ee21";
}
.nc-icon-mini.business_currency-dollar:before {
    content: "\ee22";
}
.nc-icon-mini.business_currency-euro:before {
    content: "\ee23";
}
.nc-icon-mini.business_currency-pound:before {
    content: "\ee24";
}
.nc-icon-mini.business_currency-yen:before {
    content: "\ee25";
}
.nc-icon-mini.business_factory:before {
    content: "\ee26";
}
.nc-icon-mini.business_globe:before {
    content: "\ee27";
}
.nc-icon-mini.business_goal-64:before {
    content: "\ee28";
}
.nc-icon-mini.business_goal-65:before {
    content: "\ee29";
}
.nc-icon-mini.business_hammer:before {
    content: "\ee2a";
}
.nc-icon-mini.business_handout:before {
    content: "\ee2b";
}
.nc-icon-mini.business_hat:before {
    content: "\ee2c";
}
.nc-icon-mini.business_hierarchy-53:before {
    content: "\ee2d";
}
.nc-icon-mini.business_math:before {
    content: "\ee2e";
}
.nc-icon-mini.business_money-11:before {
    content: "\ee2f";
}
.nc-icon-mini.business_money-12:before {
    content: "\ee30";
}
.nc-icon-mini.business_money-13:before {
    content: "\ee31";
}
.nc-icon-mini.business_money-bag:before {
    content: "\ee32";
}
.nc-icon-mini.business_net:before {
    content: "\ee33";
}
.nc-icon-mini.business_notes:before {
    content: "\ee34";
}
.nc-icon-mini.business_percentage-39:before {
    content: "\ee35";
}
.nc-icon-mini.business_pin:before {
    content: "\ee36";
}
.nc-icon-mini.business_plug:before {
    content: "\ee37";
}
.nc-icon-mini.business_progress:before {
    content: "\ee38";
}
.nc-icon-mini.business_safe:before {
    content: "\ee39";
}
.nc-icon-mini.business_sign:before {
    content: "\ee3a";
}
.nc-icon-mini.business_signature:before {
    content: "\ee3b";
}
.nc-icon-mini.business_stock:before {
    content: "\ee3c";
}
.nc-icon-mini.business_strategy:before {
    content: "\ee3d";
}
.nc-icon-mini.business_tie-01:before {
    content: "\ee3e";
}
.nc-icon-mini.business_tie-02:before {
    content: "\ee3f";
}
.nc-icon-mini.business_wallet-43:before {
    content: "\ee40";
}
.nc-icon-mini.business_wallet-44:before {
    content: "\ee41";
}
.nc-icon-mini.nature_bee:before {
    content: "\ee42";
}
.nc-icon-mini.nature_butterfly:before {
    content: "\ee43";
}
.nc-icon-mini.nature_chicken:before {
    content: "\ee44";
}
.nc-icon-mini.nature_clover:before {
    content: "\ee45";
}
.nc-icon-mini.nature_collar:before {
    content: "\ee46";
}
.nc-icon-mini.nature_cow:before {
    content: "\ee47";
}
.nc-icon-mini.nature_dog-house:before {
    content: "\ee48";
}
.nc-icon-mini.nature_dog:before {
    content: "\ee49";
}
.nc-icon-mini.nature_flower-05:before {
    content: "\ee4a";
}
.nc-icon-mini.nature_flower-06:before {
    content: "\ee4b";
}
.nc-icon-mini.nature_flower-07:before {
    content: "\ee4c";
}
.nc-icon-mini.nature_food-dog:before {
    content: "\ee4d";
}
.nc-icon-mini.nature_food:before {
    content: "\ee4e";
}
.nc-icon-mini.nature_mountain:before {
    content: "\ee4f";
}
.nc-icon-mini.nature_mushroom:before {
    content: "\ee50";
}
.nc-icon-mini.nature_panda:before {
    content: "\ee51";
}
.nc-icon-mini.nature_paw:before {
    content: "\ee52";
}
.nc-icon-mini.nature_pig:before {
    content: "\ee53";
}
.nc-icon-mini.nature_plant-ground:before {
    content: "\ee54";
}
.nc-icon-mini.nature_plant-vase:before {
    content: "\ee55";
}
.nc-icon-mini.nature_rat:before {
    content: "\ee56";
}
.nc-icon-mini.nature_tree-01:before {
    content: "\ee57";
}
.nc-icon-mini.nature_tree-02:before {
    content: "\ee58";
}
.nc-icon-mini.nature_tree-03:before {
    content: "\ee59";
}
.nc-icon-mini.nature_turtle:before {
    content: "\ee5a";
}
.nc-icon-mini.nature_wood:before {
    content: "\ee5b";
}