/*
* @subsection   Smart Header Mobile
*
* @description  Declare styles of header elements in mobile mode
*/


@media (max-width: $screen-md-min - 1) {
    .page-wrapper{
        padding-top: 15px;
    }

// Customer menu

    .header-switchers{
        display: none;
    }

    .sm-header_customer-menu-container{
        position: fixed;
        z-index: 999;
        top: $sm-header-panel-height;
        right: 0;
        left: 0;
        height: 0;
    }

    .sm-header_customer-menu-toggle {
        display: block;
        position: fixed;
        top: 25px;
        right: 62px;
        font-family: $default-icon-family;
        width: $sm-header-panel-height;
        height: $sm-header-panel-height;
        @include toggle-icons-via-rotation($sm-header-panel-height, 20px, $sm-header-contrast-color, '', '\ea5c');
        z-index: 20;

        span:before {
            background: url(../../images/icons/account.png) center center no-repeat;
            background-size: 35px 35px;
        }
    }

    .page-header__panel-top {
        background: #000;
        height: 25px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 16;
        padding: 0 10px;
    }

    .link-header-top {
        text-align: left;
        width: calc(100% - 70px);
        float: left;
        padding: 5px 0 2px 0;

        .item {
            color: $white;
            margin: 0 8px;
            font-size: 12px;
            font-weight: bold;
            line-height: 14px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .header-social {
        text-align: right;
        font-size: 12px;
        position: relative;
        top: 6px;

        a {
            display: inline-block;
        }

        img {
            max-width: 14px;
            max-height: 13px;
            width: auto;
            height: auto;

            &.show-desktop {
                display: none;
            }
        }

        .icon-social {
            margin: 0 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        .tel {
            font-size: 0;
            font-weight: bold;
            margin-right: 3px;
        }
    }

    .sm-header_customer-menu-container .sm-header_customer-menu .welcome{
        display: block;
        color: $default;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        padding: 28px 16px;
        border-bottom: 1px solid $border-color;
    }

    .sm-header_customer-menu-wrap.ui-dialog{
        position: fixed;
        z-index: 20;
        top: $sm-header-panel-height;
        right: 0;
        bottom: 0;
        width: 320px;

        .ui-dialog-buttonpane{
            display: none;
        }

        &.active{
            visibility: visible;
            box-shadow: $default-header-shadow;
        }

        .links li{
            display: block;
        }
        .links a{
            display: block;
            color: $primary;
            font-size: 16px;
            padding: 17px 16px;
            border-bottom: 1px solid $border-color;
            &:hover{
                font-weight: 700;
                background: $third-color;
            }

            .icon {
                display: none;
            }
        }

        .switcher{
            border-bottom: 1px solid $border-color;

            .switcher-options{
                display: block;
            }
            .switcher-trigger{
                cursor: pointer;
                display: block;
                color: $text-light;
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
                padding: 17px 16px;
                &:after{
                    @include font-icon__pseudo('\e926');
                    padding-left: 4px;
                }
                &:hover{
                    color: $primary;
                }
                &.active:after{
                    content: '\e929';
                }
                span{
                    vertical-align: text-top;
                }
            }

            .mage-dropdown-dialog{
                position: relative;
                padding: 0 8px 0 32px;

                li{
                    margin-bottom: 16px;
                }
                a{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 700;
                    color: $text-light;
                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
    }

    .sm-header_customer-menu.ui-dialog-content {
        display: block;
        position: fixed;
        top: 81px;
        right: 0;
        width: 320px;
        height: 100%;
        background: $white;
        box-shadow: $default-header-shadow;
        transition: $default-transition;
        text-align: left;
        overflow: auto;
        @include transform(translateX(100vw));
    }

    .sm-header_customer-menu-container.active .sm-header_customer-menu.ui-dialog-content{
        @include transform(translateX(0));
    }

// Categories menu

    .sm-header-nav-toggle {
        @include toggle-icons-via-rotation(56px, 20px, $sm-header-contrast-color, '\ea97', '\ea5c');
        font-family: $default-icon-family;
        background: transparent;
        display: block;
        position: fixed;
        z-index: 17;
        top: 25px;
        left: 0;

        > span {
            color: $gray_7f;
        }
    }

    .sm-header-menu-wrap {
        position: fixed;
        z-index: 999;
        top: $sm-header-panel-height;
        bottom: 0;
        left: 0;
        width: 320px;
    }

    .sm-header-nav-wrap .ui-dialog-buttonpane {
        display: none;
    }

    nav.navigation {
        position: fixed;
        top: 81px;
        left: 0;
        width: 320px;
        height: calc(100% - 56px);
        margin-top: 0;
        color: $sm-header-text-color;
        background: $white;
        box-shadow: $default-header-shadow;
        z-index: 15;
        transition: $default-transition;
        @include transform(translateX(-105%));

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .sm-header-nav-wrap.active nav.navigation {
        @include transform(translateX(0));
    }

     nav.navigation .mobile-nav-links {
         @include make-flex;
         @include justify-content(space-between);
         @include align-items(center);
         background: $gray;
         height: 56px;

         .mobile-nav-links-left {
             margin-left: 15px;
         }

         .mobile-nav-links-right {
             margin-right: 15px;
         }

         .mobile-stores-menu {
             @include desktop-top-link-icon(50px, url(../../images/icons/store-locator.png));
         }

         .mobile-others-menu {
             @include desktop-top-link-icon(50px, url(../../images/icons/others.png));
         }

         .mobile-nav-link-menu {
             display: inline-block;
             margin-top: 8px;

             a {
                 width: auto;
                 height: auto;
                 display: inline-block;
             }

             &:not(:last-child) {
                 margin-right: 5px;
             }
             .link-label {
                 display: none;
             }
         }
     }

    nav.navigation > .ui-menu {
        display: block;
        overflow: auto;
        font-size: 16px;
        line-height: 16px;
        text-align: left;
        max-height: calc(100% - 100px);

        li {
            position: relative;
            display: block;

            > a {
                padding: $sm-header-item-padding;
                border-bottom: $sm-header-item-border;
                position: static;
                display: block;
                text-transform: uppercase;

                &:hover {
                    color: $black;
                }

                &.level-top.ui-state-active, &.level-top.ui-state-focus {
                    background: $sm-header-item-bg-1;
                }
            }

            &.level1 > a {
                padding-left: 1 * $sm-header-item-padding;
            }
            &.level2 > a {
                padding-left: 4 * $sm-header-item-padding;
            }
            &.level3 > a {
                padding-left: 7 * $sm-header-item-padding;
            }
        }


        ul.mm-submenu.level1 > li > a{
            padding-left: 2 * $sm-header-item-padding;
        }
        ul.mm-submenu.level2 > li > a{
            padding: 17px 5px 17px 3 * $sm-header-item-padding;
            display: block;
        }
        ul.mm-submenu.level3 > li > a{
            padding-left: 3 * $sm-header-item-padding;
        }

        li.level-top.parent > a:after{
            @include font-icon__pseudo('\e928');
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 12px;
            line-height: 1;
        }

        li.level-top.parent > a.ui-state-active:after {
            @include font-icon__pseudo('\e929');
        }

        .submenu{
            top: auto !important;
            left: auto !important;

            &:not(.level0):not(.tm-megamenu){
                display: block !important;
            }
        }
    }

    nav.navigation .ui-menu {
        li:not(.all-category) {
            > a {
                &.ui-state-active, &.ui-state-focus {
                    font-weight: 700;
                }
            }
        }
    }

    .rd-navbar-dropdown,
    .rd-navbar-megamenu {
        display: none;
    }

    .level-top.parent {
        .rd-navbar-dropdown li li > a,
        .rd-navbar-megamenu ul li li > a {
            padding-left: 48px;
        }

        &.opened {
            > .rd-navbar-dropdown,
            > .rd-navbar-megamenu {
                display: block;
            }
        }
    }

    .rd-navbar-submenu-toggle {
        position: absolute;
        z-index: 999;
        top: 0;
        right: 0;
        width: 40px;
        height: 47px;
        line-height: 47px;
        text-align: center;
        color: $sm-header-contrast-color;
        cursor: pointer;
        transition: $default-transition;
        @include font-icon('\e926');
        &:before{
            padding-top: 12px;
        }
    }
    .opened > .rd-navbar-submenu-toggle{
        @include font-icon('\e929');
    }

    .sm-header-panel-canvas{
        position: fixed;
        height: $sm-header-panel-height;
        left: 0;
        top: 25px;
        width: 100%;
        background: $sm-header-main-color;
        z-index: 16;
        box-shadow: $default-header-shadow;
    }

    // Mega Menu
    .navigation .level-top{
        .megamenu-wrapper{
            padding: 0;
            background: transparent;
            box-shadow: none;
        }

        .ui-menu-item div[class*="col-"]{
            @include flex-basis(100%);
            padding: 0;
        }

        .widget,
        .video,
        .static-block{
            display: none;
        }

        .mm-submenu .mm-submenu{
            padding-left: 0;
        }

    }
}

@media (max-width: $screen-sm-min - 1) {
    .sm-header_customer-menu-toggle {
        right: 63px;
    }
}

@media (min-width: $screen-md-min) {
    .sm-header_customer-menu-container{
        .welcome,
        .sm-header_customer-menu-toggle,
        .switcher.currency,
        .switcher.language {
            display: none;
        }
    }

    .page-header__panel{
        .page-header__panel-left {
            display: none;
        }
    }

    .welcome{
        font-weight: 400;
    }

    .header.links li {
        padding-right: 30px;
        text-align: center;

        &:hover {
            color: $secondary-color;

            a {
                color: $secondary-color;
            }
        }

        a {
            color: $primary;
            width: auto;
            height: auto;
            display: inline-block;
            font-size: 0;
            font-weight: 700;
            @include make-flex;
            @include justify-content(center);
            @include align-items(center);
            @include flex-direction(column);

            &.compare {
                span {
                    &:before {
                        content: '(';
                    }
                    &:after {
                        content: ')';
                    }
                }
            }
        }
        &.wishlist {
            a span {
                &:before {
                    content: '(';
                }
                &:after {
                    content: ')';
                }
            }
        }
    }

    .switcher{
        position: relative;
        padding: 0;
        z-index: 99;

        &#mobile-switcher-language {
            display: none;
        }

        &.switcher-language {
            min-width: 78px;
        }

        .toggle{
            cursor: pointer;
            &:after{
                @include font-icon__pseudo('\e926');
                padding: 0 2px;
                font-size: 8px;
            }
            &.active{
                &:after{
                    @include font-icon__pseudo('\e929');
                }
            }
            &.active,
            &:hover{
                color: $primary;
            }
        }
        .dropdown{
            .mage-dropdown-dialog{
                @include transform(rotateY(-90deg));
                transition: .3s all ease;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                right: -10px;
                top: 36px;
                background: $bg-dark;
                box-shadow: $default-header-shadow;
                border: none;
                min-width: 150px;
                z-index: 15;
                transform-origin: right center;


                a{
                    display: block;
                    padding: 5px 10px;
                    color: $white;
                    &:hover {
                        color: $primary;
                    }
                }
            }
            &.active .mage-dropdown-dialog{
                opacity: 1;
                visibility: visible;
                @include transform(rotateY(0deg));
            }
        }
    }

    .sm-header-nav-toggle{
        @include toggle-icons-via-rotation(56px, 20px, $sm-header-contrast-color, '\ea97', '\ea5c');
        font-family: $default-icon-family;
        background: transparent;
        display: block;
        position: absolute;
        z-index: 100;
        top: 10px;
        left: 0;

        @media (min-width: $screen-xl-min) {
            position: absolute;
            left: auto;
            margin-left: -17px;
            top: 6px;
        }

        > span {
            color: $gray_7f;
        }

        &.active  {
            position: fixed;
            top: 0;
            left: 10px;
            margin: 0;
        }
    }

    .mobile-nav-links {
        display: none;
    }

    .tm-top-navigation > .ui-menu{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .page-header__panel-top {
        width: 100%;
        display: inline-block;

        .link-header-top {
            float: left;
            width: 70%;
            padding-left: 0;
        }

        .header-social {
            float: left;
            width: 30%;

            img {
                max-width: 22px;
            }

            .show-mobile {
                display: none;
            }
        }
    }

    .link-header-top {
        text-align: center;

        .item {
            color: $white;
            margin: 0 60px;
            font-size: 13px;
            font-weight: bold;
            line-height: 30px;
        }
    }

    .header-social {
        text-align: right;
        font-size: 13px;

        a {
            display: inline-block;
        }

        img {
            width: 28px;
        }

        .icon-social {
            margin: 0 10px;

            &:last-child {
                margin-right: 0;
            }
        }

        .tel {
            position: relative;
            top: -6px;
            font-size: 13px;
            font-weight: bold;
            margin-right: 10px;
        }
    }
}
.sm-header_customer-menu {
    display: none;

    .header.links li {
        a {
            &.compare {
                span {
                    &:before {
                        content: '(';
                    }
                    &:after {
                        content: ')';
                    }
                }
            }
        }
        &.wishlist {
            a span {
                &:before {
                    content: '(';
                }
                &:after {
                    content: ')';
                }
            }
        }
    }
}
.desktop-others-menu{
    .tracking-order-icon{
        height:50px
    }
}

.roomvo-header-icon {
    padding-bottom: 6px;
    height: 44px;
}

.mobile-roomvo {
    display: none;
}


@media (min-width: $screen-lg-min) {
    .page-header__panel-top {
        .link-header-top {
            padding-left: 14%;
        }
    }
}

@media (min-width: $screen-xl-min) {
    .page-header__panel {
        .container {
            max-width: $screen-xl-min;
        }
    }
}