.select2-container {
    display: inline-block;
    margin: 0;
    position: relative;
    text-align: left;
    vertical-align: middle;
    max-width: 250px;

    &.select2-container--open .select2-selection--single{
        background: $bg-light;

        .select2-selection__rendered{
            position: relative;
            width: 100%;
            box-shadow: 2px 1px 5px 0 rgba(0, 0, 0, 0.1);
            z-index: 99;
        }
    }

    .select2-selection--single {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        height: 50px;
        user-select: none;
        -webkit-user-select: none;

        .select2-selection__rendered {
            display: inline-block;
            padding-left: 12px;
            padding-right: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .select2-selection__clear {
            position: relative;
        }
    }

    &[dir="rtl"] {
        .select2-selection--single {
            .select2-selection__rendered {
                padding-right: 8px;
                padding-left: 20px;
            }
        }
    }

    .select2-selection--multiple {
        box-sizing: border-box;
        cursor: pointer;
        display: block;
        min-height: 32px;
        user-select: none;
        -webkit-user-select: none;

        .select2-selection__rendered {
            display: inline-block;
            overflow: hidden;
            padding-left: 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .select2-search--inline {
        float: left;

        .select2-search__field {
            box-sizing: border-box;
            border: none;
            font-size: 100%;
            margin-top: 5px;
            padding: 0;

            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }
    }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .select2-selection__rendered {
        padding-top: 5px;
    }
}

.select2-dropdown {
    background-color: $bg-light;
    border: 1px solid $border-color;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 98;
}

.select2-results {
    display: block;
    padding: 15px 20px;
}

.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}

.select2-results__option {
    padding: 0 0 4px;
    font-size: 14px;
    text-transform: capitalize;

    user-select: none;
    -webkit-user-select: none;

    &[aria-selected] {
        cursor: pointer;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.select2-container--open .select2-dropdown {
    left: 0;
}

.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    overflow-y: auto;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.select2-search--dropdown {
    display: none;
    padding: 4px;

    .select2-search__field {
        padding: 4px;
        width: 100%;
        box-sizing: border-box;

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    &.select2-search--hide {
        display: none;
    }
}

.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;

    // styles required for IE to work

    background-color: #fff;
    filter: alpha(opacity=0);
}

.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

.select2-container--default {
    .select2-selection--single {
    	position: relative;
        background-color: $input-bg-color;
        border: 1px solid $border-color;
        border-radius: 2px;
        outline: none;
        font-size: 16px;
        transition: $default-transition;

        &:hover {
            background: $bg-light;
        }

        .select2-selection__rendered {
            color: #333;
            line-height: 49px;
        }

        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
        }

        .select2-selection__placeholder {
            color: #999;
        }

        .select2-selection__arrow {
            position: absolute;
            display: inline-block;
            width: 20px;
            height: 26px;
            /*top: 3px;*/
            right: 13px;
            float: right;

            b {
                @include font-icon('\e926');
                border: none;
                height: 0;
                position: absolute;
                top: 20%;
                width: 0;
            }
        }
    }

    &[dir="rtl"] {
        .select2-selection--single {
            .select2-selection__clear {
                float: left;
            }

            .select2-selection__arrow {
                left: 1px;
                right: auto;
            }
        }
    }

    &.select2-container--disabled {
        .select2-selection--single {
            background-color: #eee;
            cursor: default;
            opacity: 0.3;

            .select2-selection__clear {
                display: none;
            }
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent #888 transparent;
                    border-width: 0 4px 5px 4px;
                }
            }
        }
    }

    .select2-selection--multiple {
        background-color: white;
        border: 1px solid #aaa;
        border-radius: 4px;
        cursor: text;

        .select2-selection__rendered {
            box-sizing: border-box;
            list-style: none;
            margin: 0;
            padding: 0 5px;
            width: 100%;
        }

        .select2-selection__placeholder {
            color: #999;

            margin-top: 5px;

            float: left;
        }

        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
            margin-top: 5px;
            margin-right: 10px;
        }

        .select2-selection__choice {
            background-color: #e4e4e4;

            border: 1px solid #aaa;
            border-radius: 4px;
            cursor: default;

            float: left;

            margin-right: 5px;
            margin-top: 5px;
            padding: 0 5px;
        }

        .select2-selection__choice__remove {
            color: #999;
            cursor: pointer;

            display: inline-block;
            font-weight: bold;

            margin-right: 2px;

            &:hover {
                color: #333;
            }
        }
    }

    &[dir="rtl"] {
        .select2-selection--multiple {
            .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
                float: right;
            }

            .select2-selection__choice {
                margin-left: 5px;
                margin-right: auto;
            }

            .select2-selection__choice__remove {
                margin-left: 2px;
                margin-right: auto;
            }
        }
    }

    &.select2-container--focus {
        .select2-selection--multiple {
            border: solid black 1px;
            outline: 0;
        }
    }

    &.select2-container--disabled {
        .select2-selection--multiple {
            background-color: #eee;
            cursor: default;
        }

        .select2-selection__choice__remove {
            display: none;
        }
    }

    &.select2-container--open.select2-container--above {
        .select2-selection--single, .select2-selection--multiple {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    &.select2-container--open.select2-container--below {
        .select2-selection--single, .select2-selection--multiple {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #aaa;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            border: none;
            outline: 0;
            box-shadow: none;
            -webkit-appearance: textfield;
        }
    }

    .select2-results > .select2-results__options {
        max-height: 300px;
        overflow-y: auto;
    }

    .select2-results__option {
        &[role=group] {
            padding: 0;
        }

        &[aria-disabled=true] {
            color: #999;
        }

        &[aria-selected=true] {
            background: none;
            color: $primary;
        }

        .select2-results__option {
            padding-left: 1em;

            .select2-results__group {
                padding-left: 0;
            }

            .select2-results__option {
                margin-left: -1em;
                padding-left: 2em;

                .select2-results__option {
                    margin-left: -2em;
                    padding-left: 3em;

                    .select2-results__option {
                        margin-left: -3em;
                        padding-left: 4em;

                        .select2-results__option {
                            margin-left: -4em;
                            padding-left: 5em;

                            .select2-results__option {
                                margin-left: -5em;
                                padding-left: 6em;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background: none;
        color: $primary;

        &:hover {
            background: $third-color;
        }
    }

    .select2-results__group {
        cursor: default;
        display: block;
        padding: 6px;
    }
}

.product.info #product-review-container .select2-container--default .select2-selection--single{
    .select2-selection__rendered{
        padding-right: 25px;
    }
    .select2-selection__arrow{
        right: 0;
    }
}


