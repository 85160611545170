[role="tablist"]{
    //Tablist title
    [role="tab"]{
        position: relative;
        cursor: pointer;
        outline: none;
        margin-bottom: $indent-small;
        display: inline-block;
        padding-right: 16px;
        @include font-icon('\e926');
        &[aria-expanded="true"]{
            @include font-icon('\e929');
        }
        &:before{
            position: absolute;
            right: 0;
            top: 2px;
        }
    }

    [role="presentation"]{
        &:first-child [role="tab"]{
            margin-top: 0;
        }
    }

    //Tablist content
    [role="tabpanel"]{
        margin-bottom: 32px;
    }
}

.block[role="tablist"]{
    .title[role="tab"]{
        display: block;
    }
}
